import { v4 as uuidv4 } from "uuid";
import { StateTopicEnum } from "../../../enums";
import { publish } from "../../../hooks";
import {
  IDeleteSingleArgs,
  IGetSingleArgs,
  IPageAndSearchArgs,
  ISearchArgs,
  IUpdateSingleArgs,
} from "../../../interfaces";
import { asyncify } from "../../../utilities";
import { goFetch } from "../../../utilities/goFetch";
import dummyData from "./categoryModel";

class Service {
  async getByPage({ pageNo, search }: IPageAndSearchArgs) {
    // limit = 10;
    const pageSize = 20;
    var response: any = await goFetch(`category/getallcategory`).get();
    var jsonResponse = await response.json();

    var category = jsonResponse.categories;
    const data = search
      ? category.filter((d: any) =>
          `${d.department.toLowerCase()}`.includes(search.toLowerCase())
        )
      : category.sort((a: any, b: any) =>
          a.department > b.department ? 1 : -1
        );

    const intPageCount = Math.floor(data.length / pageSize);
    const remPageCount = data.length - intPageCount / pageSize > 0 ? pageNo : 0;
    if (jsonResponse.success) {
      return await asyncify(
        () => ({
          pageNo,
          pageSize: pageSize,
          pageCount: intPageCount + remPageCount,
          itemCount: data.length,
          data: data.slice((pageNo - 1) * pageSize, pageNo * pageSize),
        }),
        200
      );
    }
  }

  async getSingle({ key }: IGetSingleArgs) {
    if (key.length > 0) {
      var response: any = await goFetch(`category?id=${key}`)
        .withHeaders({ "Content-Type": "application/json" })
        .get();
      var jsonResponse = await response.json();
      if (jsonResponse != null) {
        return jsonResponse;
      }
    } else {
      return await asyncify(
        () => dummyData.filter((d) => d.id === key)[0],
        200
      );
    }
  }

  async search({ search }: ISearchArgs) {
    return await asyncify(
      () => dummyData.filter((d: any) => new RegExp(search).test(d.name)),
      200
    );
  }

  async create() {
    const id = uuidv4();
    return id;
  }

  async update({ key, ...rest }: IUpdateSingleArgs) {
    await goFetch(`category/update`)
      .withHeaders({ "Content-Type": "application/json" })
      .withBody(rest)
      .post();
    publish(StateTopicEnum.LibraryRefresh, null);
  }

  async delete({ key }: IDeleteSingleArgs) {
    return await asyncify(async () => {
      var response: any = await goFetch("category/delete")
        .withQueryParameters({
          id: key,
        })
        .withHeaders({ "Content-Type": "application/json" })
        .post();
      var jsonResponse = await response.json();
      if (jsonResponse.success) {
        return jsonResponse.categories;
      }
    }, 200);
  }
}

export default new Service();
