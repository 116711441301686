import React from "react";
import TextField, { TextFieldProps } from "./TextField";

class NumberFieldProps {
  thousandSeparator?: boolean;
  decimalScale?: number;
  currency?: { symbol: string };
}

export default function NumberField({
  readOnly,
  className,
  thousandSeparator,
  decimalScale,
  currency,
  startAdornment,
  type,
  pattern,
  ...props
}: NumberFieldProps & TextFieldProps) {
  const { onChange, ...other } = props;
  return (
    <TextField
      {...props}
      pattern={pattern}
      className="h-app-number-field"
      onBlur={(e) => {
        var strVal = e.target.value ?? "0.00";
        var val = parseFloat(strVal);
        if (isNaN(val)) {
          strVal = "0.00";
        } else {
          strVal = val.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
        if (onChange)
          onChange({
            ...e,
            target: {
              ...e.target,
              value: strVal.replace(/\B(?=(\d{3})+(?!\d))/g, ","),
            },
          });
      }}
      onChange={onChange}
      type={type}
      startAdornment={
        currency ? (
          <span className="currency-symbol">{currency.symbol}</span>
        ) : (
          startAdornment
        )
      }
    />
  );
}
