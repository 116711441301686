enum StateTopicEnum {
  LibrarySearch = "_librarySearch",
  LibraryPage = "_libraryPage",
  LibrarySearchAndPage = "_librarySearchAndPage",
  Modal = "_modal",
  Alert = "_alert",
  Dialog = "_dialog",
  User = "_user",
  LibraryRefresh = "_libraryRefresh",
  DataRefresh = "_dataRefresh",
}

export default StateTopicEnum;
