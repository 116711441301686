import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { AppLogo, Button } from "../../components";
import {
  publish,
  useAuthentication,
  useStateReducer,
  useSubscription,
} from "../../hooks";
import { classNameBuilder } from "../../utilities";
import { global } from "../../_config";
import {
  FileUpload,
  List,
  NumberField,
  TextField,
} from "../../components/fields";
import "./_styles.scss";
import { useNavigate } from "react-router-dom";
import { hideAlert, showAlert } from "../../components/alert/Alert";
import { Typography } from "@mui/material";
import isEmailValid, {
  isIbanValid,
  isNumberValid,
  isPasswordValid,
  isSortCodeValid,
  isSwiftValid,
  isUrlValid,
  validateAccountNo,
} from "../../utilities/validations";

const RegisterPage = () => {
  const navigate = useNavigate();
  const [state, setState] = useStateReducer({
    currentStep: 1,
    supplierName: "",
    email: "",
    password: "",
    confirmPassword: "",
    tradingName: "",
    website: "",
    mov: "",
    countyServiced: [],
    supplierLogo: "",
    roTelNumber: "",
    roEmail: "",
    osTelNumber: "",
    osEmail: "",
    oContactPerson: "",
    oTelNumber: "",
    oEmail: "",
    aContactPerson: "",
    aTelNumber: "",
    aEmail: "",
    addressLine1: "",
    addressLine2: "",
    addressLine3: "",
    town: "",
    county: "",
    areaCode: "",
    proofOfAddress: "",
    companyRegNo: "",
    regDocument: "",
    companyVatRegNo: "",
    vatRegDocument: "",
    bank: "",
    branchName: "",
    branchCode: "",
    accHolderName: "",
    iban: "",
    sortCode: "",
    swiftCode: "",
    bankAccProof: "",
    suppAndTradeDoc: "",
    error: {},
    submitting: false,
  });
  const auth = useAuthentication();
  const [counties, setCounty] = useState<any[]>([]);
  const [countyServiced, setSelectedCounty] = useState<any[]>([]);

  const onNextStep = async () => {
    if (conditionsMet()) {
      if (state.currentStep === 8) {
        setState({ ...state, submitting: true, loading: true });
        handleRegister();
      } else {
        setState({
          ...state,
          currentStep: state.currentStep + 1,
          submitting: false,
        });
      }
    }
  };

  const onBack = async () => {
    if (state.currentStep === 1) {
      navigate(-1);
      return;
    }
    setState({ ...state, currentStep: state.currentStep - 1 });
  };

  const handleTextChange = (e: any) => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value });
  };

  const handleChange = (prop: string, value: any) => {
    if (Array.isArray(value)) {
      setState({
        [prop]: value?.map((v: any) => v),
        errorHightlight: false,
      });

      setSelectedCounty(value);
    } else {
      setState({
        [prop]: value,
        errorHightlight: false,
      });
    }
    publish("h-user", { ...state, [prop]: value });
  };

  const handleRegister = async () => {
    setState({ ...state, submitting: true });
    const res = await auth?.signUp(state);
    setState({ submitting: false });

    if (res && res.id && res.userStatus === "Accepted")
      navigate("/", { replace: true });
    else {
      setState({ error: true, errorHightlight: true, password: "" });

      setTimeout(() => setState({ error: false }), 1000);
      let message = "Your access is pending. We will notify you once accepted.";
      showAlert({
        content: message,
        options: {
          actions: [
            {
              text: "Ok",
              onClick: () => {
                navigate("/login");
                hideAlert();
              },
            },
          ],
        },
      });
    }
  };
  const getAllCounties = async () => {
    var response: any = await fetch("county.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    var jsonResponse = await response.json();
    setCounty(jsonResponse);
    setSelectedCounty(state.countyServiced);
  };

  useEffect(() => {
    getAllCounties();
  }, []);

  const conditionsMet = () => {
    let error = {};
    if (state.currentStep === 1) {
      if (!state.supplierName) {
        error = { ...error, supplierName: "Supplier Name is required" };
      }
      if (state.tradingName === state.supplierName) {
        error = {
          ...error,
          tradingName: "Mandatory if Trading name differs from Company Name",
        };
      }
      if (!state.email) {
        error = { ...error, email: "Email is required" };
      } else if (!isEmailValid(state.email)) {
        error = { ...error, email: "Invalid email address." };
      }
      if (state.website && !isUrlValid(state.website)) {
        error = { ...error, website: "Invalid URL." };
      }
    } else if (state.currentStep === 2) {
      if (state.roEmail && !isEmailValid(state.roEmail)) {
        error = { ...error, roEmail: "Invalid email address." };
      }
      if (state.roTelNumber && !isNumberValid(state.roTelNumber)) {
        error = { ...error, roTelNumber: "Invalid telephone number." };
      }
      if (state.osEmail && !isEmailValid(state.osEmail)) {
        error = { ...error, osEmail: "Invalid email address." };
      }
      if (state.osTelNumber && !isNumberValid(state.osTelNumber)) {
        error = { ...error, osTelNumber: "Invalid telephone number." };
      }
    } else if (state.currentStep === 3) {
      if (state.oEmail && !isEmailValid(state.oEmail)) {
        error = { ...error, oEmail: "Invalid email address." };
      }
      if (state.oTelNumber && !isNumberValid(state.oTelNumber)) {
        error = { ...error, oTelNumber: "Invalid telephone number." };
      }
      if (state.aEmail && !isEmailValid(state.aEmail)) {
        error = { ...error, aEmail: "Invalid email address." };
      }
      if (state.aTelNumber && !isNumberValid(state.aTelNumber)) {
        error = { ...error, aTelNumber: "Invalid telephone number." };
      }
    } else if (state.currentStep === 4) {
      if (!state.addressLine1) {
        error = { ...error, addressLine1: "Address Line 1 is required" };
      }
      if (!state.addressLine2) {
        error = { ...error, addressLine2: "Address Line 2 is required" };
      }
      if (!state.areaCode) {
        error = { ...error, areaCode: "Area Code is required" };
      }
      if (!state.town) {
        error = { ...error, town: "Town is required" };
      }
      if (!state.county || state.county.county_name === "") {
        error = { ...error, county: "County is required" };
      }
    } else if (state.currentStep === 5) {
      if (!state.companyRegNo) {
        error = {
          ...error,
          companyRegNo: "Company Registration Number is required",
        };
      }
      if (!state.regDocument) {
        error = {
          ...error,
          regDocument: "Company Registration Document is required",
        };
      }
      if (state.companyVatRegNo && !state.vatRegDocument) {
        error = {
          ...error,
          vatRegDocument: "Company Vat Registration Document is required",
        };
      }
    } else if (state.currentStep === 6) {
      if (state.accNo && state.accHolderName && !state.bankAccProof) {
        error = {
          ...error,
          bankAccProof: "Bank Account Document is required",
        };
      }
      // if (state.accNo && !validateAccountNo(state.accNo)) {
      //   error = { ...error, accNo: "Invalid Account Number." };
      // }
      if (state.iban && !isIbanValid(state.iban)) {
        error = { ...error, iban: "Invalid IBAN number." };
      }
      if (state.sortCode && !isSortCodeValid(state.sortCode)) {
        error = { ...error, sortCode: "Invalid Sort Code." };
      }
      if (state.swiftCode && !isSwiftValid(state.swiftCode)) {
        error = { ...error, swiftCode: "Invalid SWIFT number." };
      }
    } else if (state.currentStep === 8) {
      if (!state.password) {
        error = { ...error, password: "Password is required" };
      }
      if (state.password && !isPasswordValid(state.password)) {
        error = {
          ...error,
          password: "Password is too short. Minimum 8 characters.",
        };
      }
      if (!state.confirmPassword) {
        error = { ...error, confirmPassword: "Confirm Password is required" };
      }
      if (state.password !== state.confirmPassword) {
        error = { ...error, confirmPassword: "Passwords do not match" };
      }
    }

    setState({ ...state, error });

    return Object.keys(error).length === 0;
  };

  useSubscription("fileValue", (file: any) => {
    setState({ [file.name]: file.blob, errorHightlight: false });
    publish("h-user", { ...state, [file.name]: file.blob });
  });

  return (
    <div
      className={classNameBuilder(
        "h-app-register",
        state.loading ? "in" : "",
        state.error ? "error" : ""
      )}
    >
      <Helmet>
        <title>Register - {global.applicationName}</title>
      </Helmet>

      <div className="logo-container">
        <AppLogo />
      </div>
      {state.currentStep === 1 && (
        <div className="step">
          <div className="row">
            <TextField
              placeholder="Company Registered Name"
              value={state.supplierName}
              onChange={handleTextChange}
              required
              label="Company Registered Name"
              name="supplierName"
              error={state.error.supplierName}
              helperText={state.error.supplierName}
            />
          </div>
          <div className="row">
            <TextField
              placeholder="Trading Name"
              value={state.tradingName}
              onChange={handleTextChange}
              label="Trading Name"
              name="tradingName"
              error={state.error.tradingName}
              helperText={state.error.tradingName}
            />
          </div>
          <div className="row">
            <TextField
              placeholder="Email Address"
              value={state.email}
              onChange={handleTextChange}
              required
              label="Email Address"
              name="email"
              type={"email"}
              error={state.error.email}
              helperText={state.error.email}
            />
          </div>
          <div className="row">
            <TextField
              placeholder="Website"
              value={state.website}
              onChange={handleTextChange}
              label="Website"
              name="website"
              error={state.error.website}
              helperText={state.error.website}
            />
          </div>
          <span>Website in format https://www.yoururl.com/ie</span>
          <div className="row">
            <NumberField
              placeholder="Minimum Order Value"
              value={state.mov}
              onChange={handleTextChange}
              required
              label="Minimum Order Value"
              startAdornment={"€ "}
              decimalScale={2}
              name="mov"
              type={"number"}
              pattern={"/^[0-9]+$/"}
            />
          </div>
          <List
            multiple={true}
            options={counties.map((county: any) => county.county_name)}
            label="County Serviced"
            className="countyServiced"
            id="countyServiced"
            value={countyServiced}
            isOptionEqualToValue={(o, v) => o === v}
            onChange={(e, v) => handleChange("countyServiced", v)}
            disableClose={true}
          />
          <FileUpload
            label="Supplier Logo"
            className="file"
            value={{
              display:
                state.supplierLogo.length > 1
                  ? "SupplierLogo"
                  : state.supplierLogo,
              path: state.supplierLogo,
            }}
            acceptedTypes={["png", "jpg"]}
            name={"supplierLogo"}
          />
        </div>
        //
      )}
      {state.currentStep === 2 && (
        <div className="step">
          <Typography>
            <h2>Registered Office Contact</h2>
          </Typography>
          <div className="row">
            <TextField
              placeholder="Telephone Number"
              value={state.roTelNumber}
              onChange={handleTextChange}
              required
              label="Telephone Number"
              name="roTelNumber"
              error={state.error.roTelNumber}
              helperText={state.error.roTelNumber}
              type={"tel"}
            />
          </div>
          <span>Tel Number format - +353 0 00 000 000</span>
          <div className="row"></div>
          <div className="row">
            <TextField
              placeholder="Email"
              value={state.roEmail}
              onChange={handleTextChange}
              required
              label="Email Address"
              name="roEmail"
              error={state.error.roEmail}
              helperText={state.error.roEmail}
              type={"email"}
            />
          </div>
          <Typography>
            <h2>Office Support Contact</h2>
          </Typography>
          <div className="row">
            <TextField
              placeholder="Telephone Number"
              value={state.osTelNumber}
              onChange={handleTextChange}
              required
              label="Telephone Number"
              name="osTelNumber"
              error={state.error.osTelNumber}
              helperText={state.error.osTelNumber}
              type={"tel"}
            />
          </div>
          <span>Tel Number format - +353 0 00 000 000</span>
          <div className="row">
            <TextField
              placeholder="Email"
              value={state.osEmail}
              onChange={handleTextChange}
              required
              label="Email Address"
              name="osEmail"
              error={state.error.osEmail}
              helperText={state.error.osEmail}
              type={"email"}
            />
          </div>
        </div>
      )}
      {state.currentStep === 3 && (
        <div className="step">
          <Typography>
            <h2>Orders Contact</h2>
          </Typography>
          <div className="row">
            <TextField
              placeholder="Contact Person"
              value={state.oContactPerson}
              onChange={handleTextChange}
              label="Contact Person"
              name="oContactPerson"
              error={state.error.oContactPerson}
              helperText={state.error.oContactPerson}
            />
          </div>
          <div className="row">
            <TextField
              placeholder="Telephone Number"
              value={state.oTelNumber}
              onChange={handleTextChange}
              label="Telephone Number"
              name="oTelNumber"
              error={state.error.oTelNumber}
              helperText={state.error.oTelNumber}
              type={"tel"}
            />
          </div>
          <span>Tel Number format - +353 0 00 000 000</span>
          <div className="row">
            <TextField
              placeholder="Email"
              value={state.oEmail}
              onChange={handleTextChange}
              label="Email Address"
              name="oEmail"
              error={state.error.oEmail}
              helperText={state.error.oEmail}
              type={"email"}
            />
          </div>
          <Typography>
            <h2>Accounts Contact</h2>
          </Typography>
          <div className="row">
            <TextField
              placeholder="Contact Person"
              value={state.aContactPerson}
              onChange={handleTextChange}
              label="Contact Person"
              name="aContactPerson"
              error={state.error.aContactPerson}
              helperText={state.error.aContactPerson}
            />
          </div>
          <div className="row">
            <TextField
              placeholder="Telephone Number"
              value={state.aTelNumber}
              onChange={handleTextChange}
              label="Telephone Number"
              name="aTelNumber"
              error={state.error.aTelNumber}
              helperText={state.error.aTelNumber}
              type={"tel"}
            />
          </div>
          <span>Tel Number format - +353 0 00 000 000</span>
          <div className="row">
            <TextField
              placeholder="Email"
              value={state.aEmail}
              onChange={handleTextChange}
              label="Email Address"
              name="aEmail"
              error={state.error.aEmail}
              helperText={state.error.aEmail}
              type={"email"}
            />
          </div>
        </div>
      )}
      {state.currentStep === 4 && (
        <div className="step">
          <Typography>
            <h2>Registered Business Address</h2>
          </Typography>
          <div className="row">
            <TextField
              placeholder="Address Line 1"
              value={state.addressLine1}
              onChange={handleTextChange}
              required
              label="Address Line 1"
              name="addressLine1"
              error={state.error.addressLine1}
              helperText={state.error.addressLine1}
            />
          </div>
          <div className="row">
            <TextField
              placeholder="Address Line 2"
              value={state.addressLine2}
              onChange={handleTextChange}
              required
              label="Address Line 2"
              name="addressLine2"
              error={state.error.addressLine2}
              helperText={state.error.addressLine2}
            />
          </div>
          <div className="row">
            <TextField
              placeholder="Address Line 3"
              value={state.addressLine3}
              onChange={handleTextChange}
              required
              label="Address Line 3"
              name="addressLine3"
            />
          </div>
          <div className="row">
            <TextField
              placeholder="Town"
              value={state.town}
              onChange={handleTextChange}
              required
              label="Town"
              name="town"
              error={state.error.town}
              helperText={state.error.town}
            />
          </div>
          <div className="row">
            <List
              options={counties.map((county: any) => county.county_name)}
              label={state.county === "" ? "County" : state.county}
              className="county"
              id="county"
              value={state.county}
              isOptionEqualToValue={(o, v) => o === v}
              onChange={(e, v) => handleChange("county", v)}
              error={state.error.county}
            />
          </div>
          <div className="row">
            {state.error.county && !state.county && (
              <text
                className="error"
                style={{ color: "#ff0000", fontSize: "12px" }}
              >
                County required
              </text>
            )}
          </div>
          <span>Area Code format - 12345</span>
          <div className="row">
            <TextField
              placeholder="Area Code"
              value={state.areaCode}
              onChange={handleTextChange}
              required
              label="Area Code"
              name="areaCode"
              error={state.error.areaCode}
              helperText={state.error.areaCode}
              type={"tel"}
              maxLength={5}
            />
          </div>
          <FileUpload
            label="Proof of Address"
            className="proof"
            value={{
              display:
                state.proofOfAddress.length > 1
                  ? "Proof of Address"
                  : state.proofOfAddress,
              path: state.proofOfAddress,
            }}
            acceptedTypes={["pdf"]}
            name={"proofOfAddress"}
            error={state.error.proofOfAddress}
          />
        </div>
      )}
      {state.currentStep === 5 && (
        <div className="step">
          <Typography>
            <h2>Company Details</h2>
          </Typography>
          <div className="row">
            <TextField
              placeholder="Company Registration Number"
              value={state.companyRegNo}
              onChange={handleTextChange}
              required
              label="Company Registration Number"
              name="companyRegNo"
              type={"tel"}
              error={state.error.companyRegNo}
              helperText={state.error.companyRegNo}
            />
            <FileUpload
              label="Company Reg Document"
              className="regDoc"
              value={{
                display:
                  state.regDocument.length > 1
                    ? "Company Reg Document"
                    : state.regDocument,
                path: state.regDocument,
              }}
              error={state.error.regDocument}
              acceptedTypes={["pdf"]}
              name={"regDocument"}
            />
          </div>

          <div className="row">
            <TextField
              placeholder="Company VAT Registration Number"
              value={state.companyVatRegNo}
              error={state.error.companyVatRegNo}
              onChange={handleTextChange}
              label="Company VAT Registration Number"
              type={"tel"}
              name="companyVatRegNo"
            />
            <FileUpload
              label="VAT Reg Document"
              className="vatRegDoc"
              error={state.error.vatRegDocument}
              value={{
                display:
                  state.vatRegDocument.length > 1
                    ? "VAT Reg Document"
                    : state.vatRegDocument,
                path: state.vatRegDocument,
              }}
              acceptedTypes={["pdf"]}
              name={"vatRegDocument"}
            />
          </div>
        </div>
      )}
      {state.currentStep === 6 && (
        <div className="step">
          <Typography>
            <h2>Company Banking Details</h2>
          </Typography>
          <div className="row">
            <TextField
              placeholder="Bank"
              value={state.bank}
              onChange={handleTextChange}
              label="Bank"
              name="bank"
            />
          </div>
          <div className="row">
            <TextField
              placeholder="Branch Name"
              value={state.branchName}
              onChange={handleTextChange}
              label="Branch Name"
              name="branchName"
            />
            <TextField
              placeholder="Branch Code"
              value={state.branchCode}
              onChange={handleTextChange}
              label="Branch Code"
              name="branchCode"
              type={"tel"}
              maxLength={8}
            />
          </div>
          <div className="row">
            <TextField
              placeholder="Account Holder Name"
              value={state.accHolderName}
              onChange={handleTextChange}
              label="Account Holder Name"
              name="accHolderName"
            />
          </div>
          <span>Account Number Format - 1-15 length</span>
          <div className="row">
            <TextField
              placeholder="Account Number"
              value={state.accNo}
              onChange={handleTextChange}
              label="Account Number"
              name="accNo"
              type={"tel"}
              maxLength={15}
              error={state.error.accNo}
            />
          </div>
          <span>IBAN Number Format - XX00 0000 0000 0000 0000 00</span>
          <div className="row">
            <TextField
              placeholder="IBAN"
              value={state.iban}
              onChange={handleTextChange}
              label="IBAN"
              name="iban"
              error={state.error.iban}
            />
          </div>
          <span>Sort Code Format - 00-00-00 or 000000</span>
          <div className="row">
            <TextField
              placeholder="Sort Code"
              value={state.sortCode}
              onChange={handleTextChange}
              label="Sort Code"
              name="sortCode"
              type={"tel"}
              maxLength={8}
              error={state.error.sortCode}
            />
          </div>
          <span>Swift Code Format - XXXXXXXX000</span>
          <div className="row">
            <TextField
              placeholder="Swift Code"
              value={state.swiftCode}
              onChange={handleTextChange}
              label="Swift Code"
              name="swiftCode"
              error={state.error.swiftCode}
            />
          </div>
          <FileUpload
            label="Proof of Bank Account"
            className="file"
            value={{
              display:
                state.bankAccProof.length > 1
                  ? "Proof of Bank Account"
                  : state.bankAccProof,
              path: state.bankAccProof,
            }}
            acceptedTypes={["pdf"]}
            name={"bankAccProof"}
          />
        </div>
      )}
      {state.currentStep === 7 && (
        <div className="step">
          <Typography>
            <h2>Supply And Trading Terms</h2>
          </Typography>
          <div className="row">
            <FileUpload
              label="General Supply and Trading Terms"
              className="terms"
              value={{
                display:
                  state.suppAndTradeDoc.length > 1
                    ? "Supply and Trading Document"
                    : state.suppAndTradeDoc,
                path: state.suppAndTradeDoc,
              }}
              name={"suppAndTradeDoc"}
              acceptedTypes={["pdf"]}
            />
          </div>
        </div>
      )}
      {state.currentStep === 8 && (
        <div className="step">
          <Typography>
            <h2>Password Details</h2>
          </Typography>
          <div className="row">
            <span>
              Password must consist out of minimum eight characters, at least
              one lowercase letter, one uppercase letter, one numeric digit, and
              one special character
            </span>
          </div>
          <div className="row">
            <TextField
              placeholder="Password"
              type="password"
              value={state.password}
              onChange={handleTextChange}
              required
              label="Password"
              name="password"
              error={state.error.password}
              helperText={state.error.password}
            />
          </div>
          <div className="row">
            <TextField
              placeholder="Confirm Password"
              type="password"
              value={state.confirmPassword}
              onChange={handleTextChange}
              required
              label="Confirm Password"
              name="confirmPassword"
              error={state.error.confirmPassword}
              helperText={state.error.confirmPassword}
            />
          </div>
        </div>
      )}

      <div className="button-container">
        <Button text="Back" onClick={onBack} />
        <Button
          text={state.currentStep === 8 ? "Submit" : "Next"}
          primary
          raised
          onClick={onNextStep}
          working={state.submitting}
        />
      </div>
    </div>
  );
};

export default RegisterPage;
