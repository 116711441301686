import React from "react";
import { faSave } from "@fortawesome/free-solid-svg-icons";
import { ToolButton } from "../../../../components";
import { IToolButtonProps } from "../../../../interfaces";
import { publish, useStateReducer } from "../../../../hooks";
import { useNavigate } from "react-router-dom";

export default function SaveButton({ config, item }: IToolButtonProps) {
  const { id: moduleId, services } = config;
  const { id: itemId } = item;

  const handleClick = async () => {
    publish("brands", null);
  };

  return (
    <ToolButton
      text="Save"
      icon={faSave}
      path={`/${moduleId}/${itemId}`}
      primary
      raised
      onClick={handleClick}
    />
  );
}
