import React from "react";
import { faSave } from "@fortawesome/free-solid-svg-icons";
import { ToolButton } from "../../../../components";
import { IToolButtonProps } from "../../../../interfaces";
import { useStateReducer, useSubscription } from "../../../../hooks";
import { useNavigate } from "react-router-dom";
import { hideModal, showModal } from "../../../../components/modal/Modal";
import ProductModal from "../../../../components/ProductModal/ProductModal";

export default function SaveButton({ config, item }: IToolButtonProps) {
  const [state, setState] = useStateReducer({});
  let navigate = useNavigate();

  const { id: moduleId, services } = config;
  const { id: itemId } = item;

  useSubscription("product", (product: any) => {
    const valid = !product.valid;
    setState({ product, valid });
  });
  const handleClick = async () => {
    console.log(state.product);
    if (state.product != null) {
      showModal({
        content: <ProductModal product={state.product} />,
        options: {
          title: "Add Product to Catalogue",
          actions: [
            { text: "Confirm", primary: true, onClick: handleSave },
            { text: "Edit", onClick: handleCancel },
          ],
        },
      });
    }
  };
  const handleSave = async () => {
    await services.update(state.product);
    hideModal();
    navigate(-1);
    return true;
  };

  const handleCancel = () => hideModal();

  return (
    <ToolButton
      text="Save"
      icon={faSave}
      path={`/${moduleId}/${itemId}`}
      primary
      raised
      disabled={state.product == null ? !state.valid : state.valid}
      onClick={handleClick}
    />
  );
}
