import { faMoneyBill } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import "../library-card/_styles.scss";

interface LibraryCardProps {
  data?: any;
}
export default function LibraryCard({ data }: LibraryCardProps) {
  const renderSwitch = (param: string) => {
    switch (param) {
      case "Dispatched":
        return (
          <>
            <span className="badgeA">{data.orderStatus}</span>
          </>
        );
      case "Pending":
        return (
          <>
            <span className="badgeB">{data.orderStatus}</span>
          </>
        );
      case "Cancelled":
        return (
          <>
            <span className="badgeC">{data.orderStatus}</span>
          </>
        );
      case "Processing":
        return (
          <>
            <span className="badgeD">{data.orderStatus}</span>
          </>
        );

      default:
        return "Pending";
    }
  };
  return (
    <div className="module-p-library-card">
      <div>
        <div className="icon-container">
          <FontAwesomeIcon icon={faMoneyBill} />
        </div>
      </div>
      <div>
        <br />
      </div>
      <div>
        <h3 color="#1D84B5">{data.orderId}</h3>
        <span>€ {data.orderTotal}</span>
        <div>
          <br />
        </div>
        <div>
          {data.orderStatus !== null || data.orderStatus !== "" ? (
            <>{renderSwitch(data.orderStatus)}</>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
}
