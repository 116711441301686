import React from "react";
import { PageWrapper } from "../../components";
import { Outlet, useParams } from "react-router-dom";
import "./_styles.scss";
import PageContent from "./PageContent";
import { useEffect, useState } from "react";
import { moduleService } from "../../services";
import { Library } from "../../components";
import { INavigableComponent } from "../../interfaces";
import { ModuleTypeEnum, ToolbarContextEnum } from "../../enums";

export default function ModulePage({
  setBreadcrumbs,
  setToolbarConfig,
}: INavigableComponent) {
  const { moduleId, itemId } = useParams();
  const [moduleConfig, setModuleConfig] = useState<any>();
  const { name, moduleType } = moduleConfig ?? {};

  useEffect(() => {
    const loadModuleConfig = async () => {
      const cfg = await moduleService.getSingleModule({ key: moduleId ?? "" });

      setModuleConfig(cfg);
    };

    loadModuleConfig();
  }, [moduleId]);

  useEffect(() => {
    if (moduleConfig) {
      setBreadcrumbs?.([{ text: name }]);
      setModuleConfig?.({
        ...moduleConfig,
        context: ToolbarContextEnum.Library,
        item: null,
      });

      if (!itemId)
        setToolbarConfig({
          ...moduleConfig,
          context: ToolbarContextEnum.Library,
        });
    }
  }, [itemId, name]);

  if (!moduleConfig || moduleConfig.id !== moduleId) {
    return (
      <PageWrapper
        className={`module mod-loading`}
        meta={{ title: "Loading" }}
        pageId={"loading"}
        secure
      ></PageWrapper>
    );
  }

  const meta = {
    title: name,
    description: `"${name}" module page`,
  };

  return (
    <PageWrapper
      className={`module mod-${moduleId}`}
      meta={meta}
      pageId={moduleId}
      secure
    >
      <PageContent>
        {moduleType === ModuleTypeEnum.DualPaneLibrary && (
          <Library config={moduleConfig} />
        )}
        <Outlet />
      </PageContent>
    </PageWrapper>
  );
}
