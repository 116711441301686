import { showAlert, hideAlert } from "../components/alert/Alert";
import { AlertTypeEnum } from "../enums";

export default function downloadBase64File(base64Data: any, fileName: any) {
  const linkSource = `${base64Data}`;
  const downloadLink = document.createElement("a");
  downloadLink.href = linkSource;
  downloadLink.download = fileName;
  downloadLink.click();
}
const errorAlert = () => {
  showAlert({
    content: "No File to download.",
    options: {
      actions: [
        {
          text: "OK",
          onClick: hideAlert,
          primary: true,
        },
      ],
      type: AlertTypeEnum.Error,
    },
  });
};
export { downloadBase64File, errorAlert };
