import React from "react";
import { classNameBuilder } from "../../utilities";

interface InstructionWrapperProps {
  text?: string;
  error?: string | null | undefined;
  className?: string;
  row?: boolean;
  children?: React.ReactNode;
}

export default function InstructionWrapper({
  text,
  error,
  className,
  row,
  children,
}: InstructionWrapperProps) {
  return (
    <div
      className={classNameBuilder(
        "h-app-field-instruction-wrapper",
        className ?? ""
      )}
    >
      <div
        className={classNameBuilder("instruction-field", row ? "flex-row" : "")}
      >
        {children}
      </div>
      <div className="instruction-text">
        {text}
        {error && <span className="error">{error}</span>}
      </div>
    </div>
  );
}
