import React from "react";
import { Checkbox as MUICheckbox, FormControlLabel } from "@mui/material";
import { classNameBuilder } from "../../utilities";

interface CheckboxProps {
  label: string;
  value?: boolean | undefined;
  error?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  className?: string;
  id?: string;
  onChange?: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => void;
}

export default function TextField({
  label,
  value,
  className,
  id,
  onChange,
  disabled,
  readOnly,
}: CheckboxProps) {
  return (
    <FormControlLabel
      control={<MUICheckbox checked={value} onChange={onChange} />}
      id={id}
      label={label}
      className={classNameBuilder(
        "h-app-input-field",
        "h-app-checkbox",
        readOnly ? "readonly" : "",
        className ?? ""
      )}
      disabled={disabled || readOnly}
    />
  );
}
