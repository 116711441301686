import React from "react";
import { TextField } from "../../../../components/fields";
import Form from "../../../../components/form/Form";
import "./_styles.scss";
import Heading from "../heading/Heading";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import { AccordionDetails, Divider, Typography } from "@mui/material";
import { asyncify, downloadBase64File } from "../../../../utilities";
import { Button } from "../../../../components";
import { icon } from "@fortawesome/fontawesome-svg-core";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { showAlert, hideAlert } from "../../../../components/alert/Alert";
import { AlertTypeEnum } from "../../../../enums";

interface ViewItemProps {
  data: any;
}

export default function ViewItem({ data }: ViewItemProps) {
  const errorAlert = () => {
    showAlert({
      content: "No File to download.",
      options: {
        actions: [
          {
            text: "OK",
            onClick: hideAlert,
            primary: true,
          },
        ],
        type: AlertTypeEnum.Error,
      },
    });
  };
  return (
    <div className="buyer-item">
      <div className="item-header">
        <Heading lin {...data} />
      </div>
      <Form title="Buyer Details">
        <TextField label="Buyer Name" value={data.name} readOnly />
        <TextField
          label="Buyer Trading Name"
          value={data.buyerTradingName}
          readOnly
        />
        <TextField label="Email " value={data.email} readOnly />
      </Form>
      <Form title="Company Address Details">
        <Accordion>
          <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
            <Typography>
              {data.addressLine1 == "" ? "Show Address" : data.addressLine1}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography style={{ fontWeight: "700" }}>
              Address Line 1
            </Typography>
            <Typography>{data.addressLine1}</Typography>
            <Divider />
            <br />

            <Typography style={{ fontWeight: "700" }}>
              Address Line 2
            </Typography>
            <Typography>{data.addressLine2}</Typography>
            <Divider />
            <br />
            <Typography style={{ fontWeight: "700" }}>
              Address Line 3
            </Typography>
            <Typography>{data.addressLine3}</Typography>
            <Divider />
            <br />
            <Typography style={{ fontWeight: "700" }}>Area Code</Typography>
            <Typography>{data.postalCode}</Typography>
            <Divider />
            <br />
            <Typography style={{ fontWeight: "700" }}>Town</Typography>
            <Typography>{data.town}</Typography>
            <Divider />
            <br />
            <Typography style={{ fontWeight: "700" }}>County</Typography>
            <Typography>{data.county}</Typography>
          </AccordionDetails>
        </Accordion>
      </Form>
      <Form title="Delivery Address Details">
        <Accordion>
          <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
            <Typography>
              {data.dAddressLine1 == "" ? "Show Address" : data.dAddressLine1}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography style={{ fontWeight: "700" }}>
              Address Line 1
            </Typography>
            <Typography>{data.dAddressLine1}</Typography>
            <Divider />
            <br />

            <Typography style={{ fontWeight: "700" }}>
              Address Line 2
            </Typography>
            <Typography>{data.dAddressLine2}</Typography>
            <Divider />
            <br />
            <Typography style={{ fontWeight: "700" }}>
              Address Line 3
            </Typography>
            <Typography>{data.dAddressLine3}</Typography>
            <Divider />
            <br />
            <Typography style={{ fontWeight: "700" }}>Postal Code</Typography>
            <Typography>{data.dAreaCode}</Typography>
            <Divider />
            <br />
            <Typography style={{ fontWeight: "700" }}>City</Typography>
            <Typography>{data.dTown}</Typography>
            <Divider />
            <br />
            <Typography style={{ fontWeight: "700" }}>Country</Typography>
            <Typography>{data.dCounty}</Typography>
          </AccordionDetails>
        </Accordion>
      </Form>
      <Form title="Company Registration Details">
        <TextField
          label="Company Registration Number"
          value={data.companyRegNo}
          readOnly
        />
        <TextField
          label="Company Registration Document"
          value={
            data.regDocument === ""
              ? "No File"
              : "Company Registration Document"
          }
          readOnly
          name="regDocument"
          endAdornment={
            <Button
              icon={faDownload}
              primary
              onClick={(_) =>
                asyncify(
                  () =>
                    data.regDocument === "" || data.regDocument === null
                      ? errorAlert()
                      : downloadBase64File(data.regDocument, "RegDocument.pdf"),
                  0
                )
              }
              className="clear-button"
            />
          }
        />
        <TextField
          label="Company VAT Registration Number "
          value={data.companyVATRegNo}
          readOnly
        />
        <TextField
          label="Company VAT Registration Document"
          value={
            data.vatRegDocument === ""
              ? "No File"
              : "Company VAT Registration Document"
          }
          readOnly
          name="vatRegDocument"
          endAdornment={
            <Button
              icon={faDownload}
              primary
              onClick={(_) =>
                asyncify(
                  () =>
                    data.vatRegDocument === "" || data.vatRegDocument === null
                      ? errorAlert()
                      : downloadBase64File(
                          data.vatRegDocument,
                          "VatRegDocument.pdf"
                        ),
                  0
                )
              }
              className="clear-button"
            />
          }
        />

        <TextField label="Status " value={data.userStatus} readOnly />
      </Form>
    </div>
  );
}
