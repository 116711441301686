import React from 'react';
import { classNameBuilder } from '../../utilities';
import './_styles.scss';

interface FormProps {
  title?: string;
  className?: string;
  children?: React.ReactNode;
}

export default function Form({ title, className, children }: FormProps) {
  return (
    <form className={classNameBuilder('h-app-form', className ?? '')}>
      {title && <h1>{title}</h1>}
      {children}
    </form>
  );
}
