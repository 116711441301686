import React from "react";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ButtonBase } from "@mui/material";
import { useEffect } from "react";
import { useStateReducer } from "../../hooks";
import { classNameBuilder } from "../../utilities";
import "./_styles.scss";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { Nullable } from "../../types";

interface ButtonProps {
  icon?: IconProp;
  text?: string;
  onClick?(
    e: React.MouseEventHandler<HTMLButtonElement>
  ): Nullable<Promise<any>>;
  className?: string;
  primary?: boolean;
  raised?: boolean;
  capitalize?: boolean;
  disabled?: boolean;
  working?: boolean;
}

export default function Button({
  icon,
  text,
  onClick,
  className,
  primary,
  raised,
  capitalize,
  disabled,
  working,
}: ButtonProps) {
  const [state, setState] = useStateReducer({ working: working });

  const handleClick = (e: any) => {
    if (!onClick) return;

    const res = onClick(e);

    if (res instanceof Promise) {
      setState({ working: true });
      res.then(() => setState({ working: false }));
    }
  };

  useEffect(() => {
    setState({ working });
  }, [working, setState]);

  return (
    <ButtonBase
      onClick={handleClick}
      className={classNameBuilder(
        "h-app-button",
        primary ? "primary" : "",
        raised ? "raised" : "",
        capitalize ? "capitalize" : "",
        className ?? ""
      )}
      disabled={disabled || state.working}
    >
      {icon && (
        <FontAwesomeIcon
          icon={state.working ? faSpinner : icon}
          spin={state.working}
        />
      )}
      {text}
      {!icon && state.working && (
        <FontAwesomeIcon icon={faSpinner} spin={true} className="end" />
      )}
    </ButtonBase>
  );
}
