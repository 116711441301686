import React, { useEffect, useState } from "react";
import { Button, Form } from "../../../../components";
import { TextField } from "../../../../components/fields";
import Heading from "../heading/Heading";
import "./_styles.scss";
import Moment from "moment";
import { goFetch } from "../../../../utilities/goFetch";
import { Grid } from "@mui/material";
import InvoiceModal from "../../../../components/InvoiceModal/InvoiceModal";
import { hideModal, showModal } from "../../../../components/modal/Modal";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
interface ViewItemProps {
  data?: any;
}

export default function ViewItem({ data }: ViewItemProps) {
  const [buyer, setBuyer] = useState<any>();
  const [supplier, setSupplier] = useState<any>();
  useEffect(() => {
    getBuyer();
    getSupplier();
    return () => {};
  }, []);
  const getBuyer = async () => {
    var response: any = await goFetch(`user?id=${data.buyerId}`).post();
    var jsonResponse = await response.json();

    setBuyer(jsonResponse.users);
  };
  const getSupplier = async () => {
    var response: any = await goFetch(`user?id=${data.supplierId}`).post();

    var jsonResponse = await response.json();
    setSupplier(jsonResponse.users);
  };
  const buyerName = buyer?.name.toString();
  data.createdAt = Moment().format("DD/MM/YYYY");

  const getProducts = (id: string, products: any) => {
    return products.filter((e: any) => e.id === id);
  };

  const handleClick = async (item: any) => {
    if (data != null) {
      showModal({
        content: (
          <InvoiceModal
            order={data}
            orderItems={data.orderItems[0]}
            product={data.products}
            supplier={supplier}
          />
        ),
        options: {
          title: "Save Invoice",
          actions: [
            { text: "Confirm", primary: true, onClick: handleSave },
            { text: "Edit", onClick: handleCancel },
          ],
        },
      });
    }
  };
  const _exportPdf = () => {
    const input = document.getElementById("invoice-box");
    // console.log(input);
    html2canvas(input!).then((canvas: any) => {
      const imgData = canvas.toDataURL("application/pdf");
      const pdf = new jsPDF();
      const imgProperties = pdf.getImageProperties(imgData);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;
      pdf.addImage(imgData, "pdf", 12, 12, 180, 180, "");
      pdf.save("download.pdf");
    });
  };
  const handleSave = async () => {
    _exportPdf();
  };

  const handleCancel = () => hideModal();
  return (
    <div className="order-item">
      <div className="item-header">
        <Heading lin {...data} />
      </div>
      <Form title="Order Details">
        <TextField label="Order Number" value={data.orderId} readOnly />
        <TextField label="Order Status" value={data.orderStatus} readOnly />
        <TextField label="Order Date" value={data.createdAt} readOnly />
        <TextField label="Buyer" value={buyerName} readOnly />
        <TextField
          label="Order Total"
          value={data.orderTotal}
          startAdornment="€ "
          readOnly
        />{" "}
      </Form>

      <Form title="Products">
        <div
          className="sub_div"
          style={{ position: "absolute", right: "40px" }}
        >
          <Button
            text="Generate Invoice"
            primary
            raised
            onClick={handleClick}
          />
        </div>
        <br />
        <div className="card-body">
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <div style={{ padding: "10px", display: "block" }}>
                <span>Product Name</span>
              </div>
            </Grid>

            <Grid item xs={2}>
              <div style={{ padding: "10px", display: "block" }}>
                <span>Quantity Ordered</span>
              </div>
            </Grid>
            <Grid item xs={3}>
              <div style={{ padding: "10px", display: "block" }}>
                <span>Quantity Available</span>
              </div>
            </Grid>
            <Grid item xs={3}>
              <div style={{ padding: "10px", display: "block" }}>
                <span>Product Total</span>
              </div>
            </Grid>
          </Grid>
        </div>
        {data.orderItems.map((orderItem: any, index: any) => {
          var product = getProducts(orderItem.productId, data.products);
          return (
            <div className="card-body">
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <div style={{ padding: "10px", display: "block" }}>
                    <div className="field-wrap">{product.title}</div>
                  </div>
                </Grid>

                <Grid item xs={2}>
                  <div style={{ padding: "10px", display: "block" }}>
                    <div className="field-wrap">{orderItem.quantity}</div>
                  </div>
                </Grid>
                <Grid item xs={3}>
                  <div style={{ padding: "10px", display: "block" }}>
                    <div className="field-wrap">
                      <TextField
                        type={"number"}
                        label="Quantity Available"
                        value={orderItem.acceptedQuantity}
                        readOnly
                      />
                    </div>
                  </div>
                </Grid>
                <Grid item xs={3}>
                  <div style={{ padding: "10px", display: "block" }}>
                    <div className="field-wrap">
                      € {orderItem.total.toFixed(2)}
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>
          );
        })}
      </Form>
    </div>
  );
}
