import React from "react";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { ToolButton } from "../../../../components";
import { IToolButtonProps } from "../../../../interfaces";

export default function DeleteButton({ config }: IToolButtonProps) {
  const { id: moduleId } = config;

  return <ToolButton text="Delete" icon={faTrash} path={`/${moduleId}`} />;
}
