import { v4 as uuidv4 } from "uuid";
import { StateTopicEnum } from "../../../enums";
import { publish } from "../../../hooks";
import {
  IDeleteSingleArgs,
  IGetSingleArgs,
  IPageAndSearchArgs,
  ISearchArgs,
  IUpdateSingleArgs,
} from "../../../interfaces";
import { asyncify } from "../../../utilities";
import { goFetch } from "../../../utilities/goFetch";
import dummyData from "./brandModel";

class Service {
  async getByPage({ search, pageNo }: IPageAndSearchArgs) {
    var response: any = await goFetch(`brand/getallbrand`).get();
    var jsonResponse = await response.json();
    const pageSize = 20;
    const data = search
      ? jsonResponse.brands.filter((d: any) =>
          `${d.name.toLowerCase()}`.includes(search.toLowerCase())
        )
      : jsonResponse.brands;
    const intPageCount = Math.floor(data.length / pageSize);
    const remPageCount = data.length - intPageCount / pageSize > 0 ? pageNo : 0;

    if (jsonResponse.success) {
      return await asyncify(
        () => ({
          pageNo,
          pageSize: pageSize,
          pageCount: intPageCount + remPageCount,
          itemCount: data.length,
          data: data
            .sort((a: any, b: any) => (a.name > b.name ? 1 : -1))
            .slice((pageNo - 1) * pageSize, pageNo * pageSize),
        }),
        200
      );
    }
  }

  async getSingle({ key }: IGetSingleArgs) {
    if (key.length > 0) {
      var response: any = await goFetch(`brand?id=${key}`)
        .withHeaders({ "Content-Type": "application/json" })
        .get();
      var jsonResponse = await response.json();
      if (jsonResponse.success) {
        return jsonResponse.brands;
      }
    } else {
      return await asyncify(
        () => dummyData.filter((d) => d.id === key)[0],
        200
      );
    }
  }

  async search({ search }: ISearchArgs) {
    return await asyncify(
      () => dummyData.filter((d: any) => new RegExp(search).test(d.name)),
      200
    );
  }

  async create() {
    const id = uuidv4();
    return id;
  }

  async update(rest: any) {
    await goFetch(`brand/update`)
      .withHeaders({ "Content-Type": "application/json" })
      .withBody(rest)
      .post();

    publish(StateTopicEnum.LibraryRefresh, null);
  }

  async delete({ key }: IDeleteSingleArgs) {
    const response: any = await goFetch("brand/delete")
      .withQueryParameters({
        id: key,
      })
      .withHeaders({ "Content-Type": "application/json" })
      .post();

    const jsonResponse = await response.json();
    return jsonResponse;
  }

  async getAll() {
    const response: any = await goFetch(`brand/getallbrand`).get();
    const jsonResponse = await response.json();
    return jsonResponse.brands;
  }
}

export default new Service();
