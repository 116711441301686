import { productDataService } from "./services";
import { ItemPage, LibraryCard } from "./components";
import {
  AddButton,
  BackButton,
  CancelButton,
  DeleteButton,
  EditButton,
  ExportButton,
  SaveButton,
} from "./components/toolbar-items";
import { faCube } from "@fortawesome/free-solid-svg-icons";
import { ModuleTypeEnum } from "../../enums";
import { IModuleConfig } from "../../interfaces";

const config: IModuleConfig = {
  id: "product-catalogue",
  name: "Product Catalogue",
  icon: faCube,
  moduleType: ModuleTypeEnum.DualPaneLibrary,
  allowSearch: true,
  services: {
    getByPage: productDataService.getByPage,
    getSingle: productDataService.getSingle,
    search: productDataService.search,
    create: productDataService.create,
    update: productDataService.update,
    delete: productDataService.delete,
  },
  components: {
    libraryCard: LibraryCard,
    itemPage: ItemPage,
  },
  utilities: {
    extractKey: (d: any) => d.id,
    extractItemPath: (d: any) => d.id,
    extractItemBreadcrumb: (d: any) => `${d.title}`,
  },
  // Add toolbar items in order they should render in the toolbar
  toolbarItems: {
    library: [AddButton],
    view: [BackButton, "|", EditButton, DeleteButton],
    edit: [SaveButton, CancelButton],
    create: [],
  },
};

export default config;
