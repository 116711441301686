import { faBox, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import "./_styles.scss";

interface LibraryCardProps {
  data?: any;
}

export default function LibraryCard({ data }: LibraryCardProps) {
  const { id, name, email } = data;
  const renderSwitch = (param: string) => {
    switch (param) {
      case "Accepted":
        return (
          <>
            <span className="badgeA">{data.userStatus}</span>
          </>
        );
      case "Pending":
        return (
          <>
            <span className="badgeB">{data.userStatus}</span>
          </>
        );
      case "Declined":
        return (
          <>
            <span className="badgeC">{data.userStatus}</span>
          </>
        );
      default:
        return "foo";
    }
  };
  return (
    <div className="module-p-library-card">
      <div>
        <div className="icon-container">
          <FontAwesomeIcon icon={faUser} />
        </div>
      </div>
      <div>
        <br />
      </div>
      <div>
        <h3 color="#1D84B5">{name}</h3>
        <h4>{email}</h4>
        {data.userStatus !== null || data.userStatus !== "" ? (
          <>{renderSwitch(data.userStatus)}</>
        ) : (
          <></>
        )}
      </div>
      <div>
        <br />
      </div>
      <div>
        <br />
      </div>
    </div>
  );
}
