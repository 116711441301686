import React from 'react';
import PageContent from './PageContent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHandPointer, faHourglass } from '@fortawesome/free-solid-svg-icons';
import { useParams, useSearchParams } from 'react-router-dom';
import { useEffect } from 'react';
import { moduleService } from '../../services';
import { Error } from '../../components';
import { getCurrentData, useStateReducer } from '../../hooks';
import { INavigableComponent } from '../../interfaces';
import { StateTopicEnum, ToolbarContextEnum } from '../../enums';

interface ItemPageProps extends INavigableComponent {
  index?: boolean;
}

export default function ItemPage({
  index,
  setBreadcrumbs,
  setToolbarConfig,
}: ItemPageProps) {
  const { moduleId, itemId } = useParams();
  const [state, setState] = useStateReducer({
    moduleConfig: null,
    itemData: null,
  });
  const { moduleConfig, itemData } = state;
  const { id } = itemData ?? {};
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const loadData = async () => {
      const cfg = await moduleService.getSingleModule({ key: moduleId ?? '' });
      const item = await cfg?.services?.getSingle({ key: itemId ?? '' });

      if (item) {
        const search = getCurrentData(StateTopicEnum.LibrarySearch) ?? '';
        const query = search ? `?search=${search}` : '';

        setBreadcrumbs?.([
          { text: cfg.name, to: `/${moduleId}${query}` },
          { text: cfg.utilities.extractItemBreadcrumb(item) },
        ]);
        setToolbarConfig?.({
          ...cfg,
          context: ToolbarContextEnum.ViewItem,
          item,
        });
      }

      setState({
        moduleConfig: cfg,
        itemData: item,
      });
    };

    if (itemId)
      setTimeout(
        () =>
          setToolbarConfig?.({
            ...moduleConfig,
            context: ToolbarContextEnum.Clear,
            item: null,
          }),
        0
      );

    setState({
      moduleConfig: null,
      itemData: null,
    });
    loadData();
  }, [moduleId, itemId, searchParams]);

  let content = <></>;

  if (index)
    content = (
      <>
        <FontAwesomeIcon icon={faHandPointer} /> Select an item in the library
        on the left
      </>
    );
  else if (!itemData)
    content = (
      <>
        <FontAwesomeIcon icon={faHourglass} /> Loading...
      </>
    );
  else {
    const Component = moduleConfig?.components?.itemPage;

    content = Component ? (
      <Component
        config={moduleConfig}
        data={itemData}
        setBreadcrumbs={setBreadcrumbs}
        setToolbarConfig={setToolbarConfig}
      />
    ) : (
      <Error
        message={`Configuration property components.viewSingle for module "${moduleConfig?.id}" has not been defined.`}
      />
    );
  }

  return (
    <PageContent index={index} loading={itemData ? false : true} itemId={id}>
      {content}
    </PageContent>
  );
}
