import React from "react";
import { TextField } from "../../../../components/fields";
import Form from "../../../../components/form/Form";
import { publish, useStateReducer } from "../../../../hooks";
import {
  asyncify,
  downloadBase64File,
  validateObject,
} from "../../../../utilities";
import Heading from "../heading/Heading";
import AutoComplete, {
  AutoCompleteOption,
} from "../../../../components/fields/AutoComplete";
import { Card, CardContent, Typography } from "@mui/material";
import Button from "../../../../components/button/Button";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { showAlert, hideAlert } from "../../../../components/alert/Alert";
import { AlertTypeEnum } from "../../../../enums";

interface ViewItemProps {
  data?: any;
}
export default function ViewItem({ data }: ViewItemProps) {
  const [state, setState] = useStateReducer({
    ...data,
  });
  const userStat = [
    { name: "Pending" },
    { name: "Accepted" },
    { name: "Declined" },
  ];
  const handleChange = (prop: string, value: any) => {
    setState({ [prop]: value });
    publish("suppliers", { ...state, [prop]: value });
  };

  const hasError = (prop: string) => (errors[prop] ? true : false);

  const errors = validateObject(
    {
      userStatus: { regex: /.+/, message: "This field is required." },
    },
    state
  );
  const errorAlert = () => {
    showAlert({
      content: "No File to download.",
      options: {
        actions: [
          {
            text: "OK",
            onClick: hideAlert,
            primary: true,
          },
        ],
        type: AlertTypeEnum.Error,
      },
    });
  };
  return (
    <div className="client-item">
      <Heading {...state.supplierName} />
      <Form>
        <Typography>
          <h2>Basic Company Details</h2>
        </Typography>
        <TextField
          label="Company Registered Name"
          value={state.supplierName}
          readOnly
        />
        <TextField label="Trading Name" value={state.tradingName} readOnly />
        <TextField label="Email " value={state.email} readOnly />
        <TextField label="Website" value={state.website} readOnly />
        <TextField label="Minimum Order Value " value={state.mov} readOnly />
        <Card sx={{ width: 300 }}>
          <CardContent>
            <img
              alt="Supplier Logo"
              style={{
                maxWidth: 250,
                maxHeight: 250,
                objectFit: "contain",
                alignSelf: "center",
              }}
              src={state.supplierLogo}
            />
          </CardContent>
        </Card>
        <Typography>
          <h2>Registered Office Contact</h2>
        </Typography>
        <TextField
          label="Telephone Number"
          value={state.roTelNumber}
          readOnly
        />
        <TextField label="Email " value={state.roEmail} readOnly />
        <Typography>
          <h2>Office Support Contact</h2>
        </Typography>
        <TextField
          label="Telephone Number"
          value={state.osTelNumber}
          readOnly
        />
        <TextField label="Email " value={state.osEmail} readOnly />
        <Typography>
          <h2>Orders Contact</h2>
        </Typography>
        <TextField
          label="Contact Person"
          value={data.oContactPerson}
          readOnly
        />
        <TextField label="Telephone Number" value={state.oTelNumber} readOnly />
        <TextField label="Email " value={state.oEmail} readOnly />
        <Typography>
          <h2>Accounts Contact</h2>
        </Typography>{" "}
        <TextField
          label="Contact Person"
          value={state.aContactPerson}
          readOnly
        />
        <TextField label="Telephone Number" value={state.aTelNumber} readOnly />
        <TextField label="Email " value={state.aEmail} readOnly />
        <Typography>
          <h2>Registered Business Address</h2>
        </Typography>
        <TextField label="Address Line 1" value={state.addressLine1} readOnly />
        <TextField label="Address Line 2" value={state.addressLine2} readOnly />
        <TextField label="Address Line 3" value={state.addressLine3} readOnly />
        <TextField label="Town" value={state.town} readOnly />
        <TextField label="County" value={state.county} readOnly />
        <TextField label="Area Code" value={state.areaCode} readOnly />
        <TextField
          label="Proof of Address"
          value={state.proofOfAddress === "" ? "No File" : "Proof of Address"}
          readOnly
          name="proofOfAddress"
          endAdornment={
            <Button
              icon={faDownload}
              primary
              onClick={(_) =>
                asyncify(
                  () =>
                    state.proofOfAddress === "" || state.proofOfAddress === null
                      ? errorAlert()
                      : downloadBase64File(
                          state.proofOfAddress,
                          "ProofOfAddress.pdf"
                        ),
                  0
                )
              }
              className="clear-button"
            />
          }
        />
        <Typography>
          <h2>Company Details</h2>
        </Typography>
        <TextField
          label="Company Registration Number"
          value={state.companyRegNo}
          readOnly
        />
        <TextField
          label="Company Registration Document"
          value={
            state.regDocument === ""
              ? "No File"
              : "Company Registration Document"
          }
          readOnly
          name="regDocument"
          endAdornment={
            <Button
              icon={faDownload}
              primary
              onClick={(_) =>
                asyncify(
                  () =>
                    state.regDocument === "" || state.regDocument === null
                      ? errorAlert()
                      : downloadBase64File(
                          state.regDocument,
                          "RegDocument.pdf"
                        ),
                  0
                )
              }
              className="clear-button"
            />
          }
        />
        <TextField
          label="Company VAT Registration Number"
          value={state.companyVATRegNo}
          readOnly
        />
        <TextField
          label="Company VAT Registration Document"
          value={
            state.vatRegDocument === ""
              ? "No File"
              : "Company VAT Registration Document"
          }
          readOnly
          name="vatRegDocument"
          endAdornment={
            <Button
              icon={faDownload}
              primary
              onClick={(_) =>
                asyncify(
                  () =>
                    state.vatRegDocument === "" || state.vatRegDocument === null
                      ? errorAlert()
                      : downloadBase64File(
                          state.vatRegDocument,
                          "VatRegDocument.pdf"
                        ),
                  0
                )
              }
              className="clear-button"
            />
          }
        />
        <Typography>
          <h2>Company Bank Details</h2>
        </Typography>
        <TextField label="Bank" value={data.bank} readOnly />
        <TextField label="Branch Name" value={data.branchName} readOnly />
        <TextField label="Branch Code" value={data.branchCode} readOnly />
        <TextField
          label="Account Holder Name"
          value={data.accHolderName}
          readOnly
        />
        <TextField label="Account Number" value={data.accNo} readOnly />
        <TextField label="IBAN" value={data.iban} readOnly />
        <TextField label="Sort Code" value={data.sortCode} readOnly />
        <TextField label="SWIFT Code" value={data.swiftCode} readOnly />
        <TextField
          label="Bank Account Proof"
          value={data.bankAccProof === "" ? "No File" : "Bank Account Proof"}
          readOnly
          name="bankAccProof"
          endAdornment={
            <Button
              icon={faDownload}
              primary
              onClick={(_) =>
                asyncify(
                  () =>
                    data.bankAccProof === "" || data.bankAccProof === null
                      ? errorAlert()
                      : downloadBase64File(
                          data.bankAccProof,
                          "BankAccProof.pdf"
                        ),
                  0
                )
              }
              className="clear-button"
            />
          }
        />
        <Typography>
          <h2>Supply And Trade Details</h2>
        </Typography>
        <TextField
          label="Supply And Trade Doc"
          value={
            state.suppAndTradeDoc === ""
              ? "No File"
              : "Supply And Trade Document"
          }
          readOnly
          name="suppAndTradeDoc"
          endAdornment={
            <Button
              icon={faDownload}
              primary
              onClick={(_) =>
                asyncify(
                  () =>
                    state.suppAndTradeDoc === "" ||
                    state.suppAndTradeDoc === null
                      ? errorAlert()
                      : downloadBase64File(
                          state.suppAndTradeDoc,
                          "SupplyAndTradeDoc.pdf"
                        ),
                  0
                )
              }
              className="clear-button"
            />
          }
        />
        <AutoComplete
          options={userStat.map(
            (option) => new AutoCompleteOption(option.name, option.name)
          )}
          label="User Status"
          prop="userStatus"
          onChange={handleChange}
          value={state.userStatus}
          error={hasError("userStatus")}
        />
      </Form>
    </div>
  );
}
