import { showAlert, hideAlert } from "../../components/alert/Alert";
import { AlertTypeEnum } from "../../enums";
import { asyncify } from "../../utilities";
import { goFetch } from "../../utilities/goFetch";

interface LoginArgs {
  username: string;
  password: string;
}

interface GetProfileArgs {
  username: string;
}

class Service {
  async checkAuthentication() {
    return asyncify(() => {
      const data = sessionStorage.getItem("h-token");
      return data ? JSON.parse(data) : null;
    }, 100);
  }
  async getUserInfo() {
    return asyncify(() => {
      const data = localStorage.getItem("h-user");
      return data ? JSON.parse(data) : null;
    }, 100);
  }

  async login({ username, password }: LoginArgs) {
    var response: any = await goFetch("auth/login-admin")
      .withBody({ Email: username, Password: password })
      .withHeaders({ "Content-Type": "application/json" })
      .post();
    var jsonResponse = await response.json();
    if (jsonResponse.success) {
      localStorage.setItem("h-user", JSON.stringify(jsonResponse.user));
      localStorage.setItem("h-user-id", jsonResponse.user.id);
      sessionStorage.setItem(
        "h-token",
        JSON.stringify(jsonResponse.user.token)
      );
      return jsonResponse.user;
    }
    return false;
  }

  async register(registerDetails: any) {
    var response: any = await goFetch("auth/register")
      .withBody(registerDetails)
      .post();
    var jsonResponse = await response.json();
    if (jsonResponse.success) {
      localStorage.setItem("h-user", JSON.stringify(jsonResponse.user));
      localStorage.setItem("h-user-id", jsonResponse.user.id);
      sessionStorage.setItem(
        "h-token",
        JSON.stringify(jsonResponse.user.token)
      );
      return jsonResponse.user;
    } else {
      showAlert({
        content: "An error occurred. Please try again.",
        options: {
          type: AlertTypeEnum.Error,
          actions: [
            {
              text: "Retry",
              onClick: () => {
                hideAlert();
              },
            },
          ],
        },
      });
    }
    return false;
  }

  async logout() {
    try {
      localStorage.removeItem("h-user");
      localStorage.removeItem("h-user-id");
      sessionStorage.removeItem("h-token");
      await goFetch("auth/logout").post();
    } catch {}
  }

  async getProfile({ username }: GetProfileArgs) {
    return asyncify(() => {
      const data = localStorage.getItem("h-user");
      const userData = data ? JSON.parse(data) : null;
      username = userData["Email"];
    }, 100);
  }

  async update(id: any, userDetails: any) {
    var response: any = await goFetch("/user/update")
      .withQueryParameters({ id: id })
      .withBody(userDetails)
      .post();
    var jsonResponse = await response.json();
    if (jsonResponse.success) {
      localStorage.setItem("h-user", jsonResponse.user);
      localStorage.setItem("h-user-id", jsonResponse.user.id);
      sessionStorage.setItem(
        "h-token",
        JSON.stringify(jsonResponse.user.token)
      );

      return jsonResponse.user;
    }

    return true;
  }
}

export default new Service();
