import ordersModule from "./orders-module";
import productModule from "./products-module";
import clientModule from "./clients-module";
import categoryModule from "./category-module";
import brandModule from "./brand-module";
import suppliersModule from "./suppliers-module";
import requestAccessModule from "./request-module";
// Export the modules in the order they should appear in the navigation menu
const modules = [
  suppliersModule,
  clientModule,
  productModule,
  ordersModule,
  categoryModule,
  brandModule,
  requestAccessModule,
];

export default modules;
