import {
  faCodePullRequest,
  faMoneyBill,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import "./_styles.scss";

interface LibraryCardProps {
  data?: any;
}
export default function LibraryCard({ data }: LibraryCardProps) {
  const renderSwitch = (param: string) => {
    switch (param) {
      case "Accepted":
        return (
          <>
            <span className="badgeA">{data.status}</span>
          </>
        );
      case "Pending":
        return (
          <>
            <span className="badgeB">{data.status}</span>
          </>
        );
      case "Declined":
        return (
          <>
            <span className="badgeC">{data.status}</span>
          </>
        );

      default:
        return "Pending";
    }
  };
  return (
    <div className="module-p-library-card">
      <div>
        <div className="icon-container">
          <FontAwesomeIcon icon={faCodePullRequest} />
        </div>
      </div>
      <div>
        <br />
      </div>
      <div>
        <h3 color="#1D84B5">{data.buyerName}</h3>
        <div>
          <br />
        </div>
        <div>
          {data.status !== null || data.status !== "" ? (
            <>{renderSwitch(data.status)}</>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
}
