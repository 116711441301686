import React from "react";
import { useEffect, useState } from "react";
import Helmet from "react-helmet";
import { BrowserRouter } from "react-router-dom";
import {
  Alert,
  BreadcrumbTrail,
  Modal,
  Scrollable,
  SideNav,
  SideNavLink,
  Toolbar,
  UserAccount,
} from "./components";
import { moduleService } from "./services";
import {
  primary,
  secondary,
  error,
  warning,
  info,
  success,
} from "./styles/materialUiTheme";
import "./styles/_bundle.scss";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { global } from "./_config";
import { useAuthentication, useStateReducer, useSubscription } from "./hooks";
import { classNameBuilder } from "./utilities";
import AppRoutes from "./AppRoutes";
import AuthProvider from "./AuthProvider";
import { StateTopicEnum } from "./enums";
import { IBreadcrumb, IModuleConfig } from "./interfaces";
import { Nullable } from "./types";

const theme = createTheme({
  palette: {
    primary,
    secondary,
    error,
    warning,
    info,
    success,
    mode: global.theme === "dark" ? "dark" : "light",
  },
  components: {
    MuiTextField: {
      defaultProps: {
        variant: "outlined",
      },
    },
  },
});

export default function App() {
  const { allowSettings } = global;
  const [state, setState] = useStateReducer({
    coverCount: 0,
    user: null,
  });
  const [modules, setModules] = useState<Nullable<IModuleConfig[]>>(null);
  const [breadcrumbs, setBreadcrumbs] = useState<IBreadcrumb[]>([]);
  const [toolbarConfig, setToolbarConfig] = useState<IModuleConfig>();
  const auth = useAuthentication();
  useEffect(() => {
    const loadModules = async () => {
      switch (state.user?.userRole?.toLowerCase() ?? "") {
        case "admin":
          setModules(await moduleService.getAllModules());
          break;
        case "supplier":
          setModules(await moduleService.getSupplierModule());
          break;
        default:
          auth?.signOut();
          break;
      }
    };
    if (state.user && state.user.userRole) {
      loadModules();
    }
  }, [auth, state.user]);

  useSubscription(StateTopicEnum.Modal, (modal: boolean) => {
    setState({ coverCount: state.coverCount + (modal ? 1 : -1) });
  });

  useSubscription(StateTopicEnum.Alert, (alert: boolean) => {
    setState({ coverCount: state.coverCount + (alert ? 1 : -1) });
  });

  useSubscription(StateTopicEnum.User, (user: any) => {
    setState({ user });
  });

  const { user, coverCount } = state;

  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <AuthProvider>
          <BrowserRouter>
            <Helmet>
              <title>PocketRep Admin Portal</title>
            </Helmet>
            <div
              className={classNameBuilder(
                "h-app-container",
                coverCount > 0 ? "has-cover" : "",
                user ? "" : "signed-out"
              )}
            >
              {user && (
                <>
                  <BreadcrumbTrail breadcrumbs={breadcrumbs} />
                  <Toolbar config={toolbarConfig} />
                  {modules && (
                    <SideNav>
                      <span></span>
                      <img
                        alt="PocketRep logo"
                        src="/PocketRep-logo.png"
                        style={{ width: "80%", margin: "30px 0" }}
                      />
                      {/* <AppLogo /> */}
                      <UserAccount allowSettings={allowSettings} />
                      <Scrollable>
                        {modules.map((m: any, i: number) => (
                          <SideNavLink
                            key={`${m.id}-${i}`}
                            to={`/${m.id}`}
                            icon={m.icon}
                          >
                            {m.name}
                          </SideNavLink>
                        ))}
                      </Scrollable>
                    </SideNav>
                  )}
                </>
              )}

              <AppRoutes
                setBreadcrumbs={setBreadcrumbs}
                setToolbarConfig={setToolbarConfig}
              />
            </div>
            <Modal />
            <Alert />
          </BrowserRouter>
        </AuthProvider>
      </LocalizationProvider>
    </ThemeProvider>
  );
}
