import React, { FC, useEffect, useState } from "react";
import MTextField from "@mui/material/TextField";
import MAutocomplete, {
  createFilterOptions,
} from "@mui/material/Autocomplete/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { Checkbox } from "@mui/material";
interface AutoCompleteProps {
  options: IAutoCompleteOption[];
  onChange: (prop: string, value: any) => void;
  onCreate?: (prop: string, value: any) => void;
  value: any;
  prop: string;
  label?: string;
  error?: any;
  multiple?: any;
  canCreate?: boolean;
  disableClose?: boolean;
}

interface IAutoCompleteOption {
  label: string;
  value: any;
}

export class AutoCompleteOption implements IAutoCompleteOption {
  label: string;
  value: any;

  constructor(name: string, value: any) {
    this.label = name;
    this.value = value;
  }
}

const AutoComplete: FC<AutoCompleteProps> = ({
  options,
  label,
  onChange,
  value,
  prop,
  error,
  canCreate,
  onCreate,
  multiple,
  disableClose,
}) => {
  const [autoCompleteOptions, setAutoCompleteOptions] = useState(options);
  const [isSelected, setSelected] = useState([]);
  const test = (e: any) => {
    if (
      e &&
      e.target &&
      e.target.value &&
      canCreate &&
      options.filter((o) => o.value === e.target.value).length === 0
    ) {
      const newOptions = [
        new AutoCompleteOption(`Create "${e.target.value}"`, e.target.value),
        ...options,
      ];
      setAutoCompleteOptions(newOptions);
    } else {
      setAutoCompleteOptions(options);
    }
  };
  useEffect(() => {
    setAutoCompleteOptions(options);

    return () => {};
  }, [options]);

  const onValueSelected = (
    event: React.SyntheticEvent<Element, Event>,

    value: any
  ) => {
    if (multiple) return onChange(prop, value);

    setSelected(value);
    if (
      value &&
      options.filter((o) => o.value === value.value).length === 0 &&
      onCreate
    ) {
      onCreate(prop, value.value);
    }
    onChange(prop, value ? value.value : null);
  };
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  return (
    <MAutocomplete
      onError={error}
      disablePortal
      id="combo-box-demo"
      options={autoCompleteOptions}
      renderInput={(params) => <MTextField {...params} label={label} />}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {option.value}
        </li>
      )}
      className="h-app-auto-complete"
      onChange={onValueSelected}
      value={value}
      onInputChange={test}
      multiple={multiple ? multiple : false}
      disableCloseOnSelect={disableClose}
    />
  );
};

export default AutoComplete;
