import React from 'react';
import { faCancel } from '@fortawesome/free-solid-svg-icons';
import { ToolButton } from '../../../../components';
import { IToolButtonProps } from '../../../../interfaces';
import { useSearchParams } from 'react-router-dom';

export default function CancelButton({ config, item }: IToolButtonProps) {
  const { id: moduleId } = config;
  const { id: itemId, isNew } = item;
  const [searchParams, setSearchParams] = useSearchParams();

  return (
    <ToolButton
      text='Cancel'
      icon={faCancel}
      path={searchParams.get('new') ? `/${moduleId}` : `/${moduleId}/${itemId}`}
    />
  );
}
