import React, { useState } from "react";
import { useEffect } from "react";
import { Button, Form, PageWrapper, Scrollable } from "../../components";
import { FileUpload, List, TextField } from "../../components/fields";
import {
  publish,
  useAuthentication,
  useStateReducer,
  useSubscription,
} from "../../hooks";
import { INavigableComponent } from "../../interfaces";
import { global } from "../../_config";
import "./_styles.scss";
import { BackButton, EditButton } from "../../_global/components/toolbar-items";
import { Card, CardContent, Typography } from "@mui/material";
import { goFetch } from "../../utilities/goFetch";
import { useNavigate } from "react-router-dom";
import StateTopicEnum from "../../enums/StateTopicEnum";
import isEmailValid, {
  isIbanValid,
  isNumberValid,
  isSortCodeValid,
  isSwiftValid,
  isUrlValid,
  validateAccountNo,
} from "../../utilities/validations";
import service from "../../services/moduleService";

export default function ProfilePage({
  setBreadcrumbs,
  setToolbarConfig,
}: INavigableComponent) {
  useEffect(() => {
    setBreadcrumbs?.([{ text: "My Profile" }]);
    setToolbarConfig?.([BackButton, "|", EditButton]);
  }, []);
  const navigate = useNavigate();
  const { extractUserFromAuthResponse } = global.utilities;
  const auth = useAuthentication();
  const user = extractUserFromAuthResponse(auth?.user ?? "");

  const [counties, setCounty] = useState<any[]>([]);
  const [countyServiced, setSelectedCounty] = useState<any[]>([]);
  const loadData = () => {
    // service.getProfile;
  };
  const [state, setState] = useStateReducer({
    supplierName: user!.supplierName,
    tradingName: user.tradingName ?? "",
    email: user.email ?? "",
    website: user.website ?? "",
    mov: user.mov ?? "",
    supplierLogo: user.supplierLogo ?? "",
    roTelNumber: user.roTelNumber ?? "",
    roEmail: user.roEmail ?? "",
    osTelNumber: user.osTelNumber ?? "",
    osEmail: user.osEmail ?? "",
    oContactPerson: user.oContactPerson ?? "",
    oTelNumber: user.oTelNumber ?? "",
    oEmail: user.oEmail ?? "",
    aContactPerson: user.aContactPerson ?? "",
    aTelNumber: user.aTelNumber ?? "",
    aEmail: user.aEmail ?? "",
    addressLine1: user.addressLine1 ?? "",
    addressLine2: user.addressLine2 ?? "",
    addressLine3: user.addressLine3 ?? "",
    town: user.town ?? "",
    county: user.county ?? "",
    areaCode: user.areaCode ?? "",
    proofOfAddress: user.proofOfAddress ?? "",
    companyRegNo: user.companyRegNo ?? "",
    regDocument: user.regDocument ?? "",
    companyVATRegNo: user.companyVATRegNo ?? "",
    vatRegDocument: user.vatRegDocument ?? "",
    bank: user.bank ?? "",
    branchName: user.branchName ?? "",
    branchCode: user.branchCode ?? "",
    accHolderName: user.accHolderName ?? "",
    accNo: user.accNo ?? "",
    iban: user.iban ?? "",
    sortCode: user.sortCode ?? "",
    swiftCode: user.swiftCode ?? "",
    bankAccProof: user.bankAccProof ?? "",
    suppAndTradeDoc: user.suppAndTradeDoc ?? "",
    countyServiced: user.countyServiced ?? [],
    userStatus: user.userStatus ?? "",
    userRole: user.userRole ?? "",
    error: {},
    submitting: false,
  });

  const meta = { title: "My Profile" };

  const handleChange = (prop: string, value: any) => {
    if (Array.isArray(value)) {
      console.log(value);
      setState({
        [prop]: value?.map((v: any) => v),
        errorHightlight: false,
      });

      setSelectedCounty(value);
    } else {
      setState({
        [prop]: value,
        errorHightlight: false,
      });
    }
    publish("h-user", { ...state, [prop]: value });
  };

  useSubscription("fileValue", (file: any) => {
    setState({ [file.name]: file.blob, errorHightlight: false });
    publish("h-user", { ...state, [file.name]: file.blob });
  });

  useSubscription(StateTopicEnum.DataRefresh, () => {});

  const getAllCounties = async () => {
    var response: any = await fetch("county.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    var jsonResponse = await response.json();
    setCounty(jsonResponse);
    setSelectedCounty(state.countyServiced);
  };

  useEffect(() => {
    getAllCounties();
  }, []);

  const handleUpdate = async () => {
    // if (conditionsMet()) {
    setState({ ...state, submitting: true });
    await goFetch(`user/update`)
      .withQueryParameters({ id: user.id })
      .withHeaders({ "Content-Type": "application/json" })
      .withBody(state)
      .post();
    var userResult = await goFetch(`user`)
      .withQueryParameters({ id: user.id })
      .post();
    setState({
      ...state,
      user: userResult,
    });
    console.log(userResult);
    // setState({ ...state, submitting: false });
    // publish(StateTopicEnum.DataRefresh, null);
    // navigate(-1);
    // return true;
    // }
  };
  const conditionsMet = () => {
    let error = {};
    if (!state.email) {
      error = { ...error, email: "Email is required" };
    } else if (!isEmailValid(state.email)) {
      error = { ...error, email: "Invalid email address." };
    }
    if (state.website && !isUrlValid(state.website)) {
      error = { ...error, website: "Invalid URL." };
    }
    if (state.roEmail && !isEmailValid(state.roEmail)) {
      error = { ...error, roEmail: "Invalid email address." };
    }
    if (state.roTelNumber && !isNumberValid(state.roTelNumber)) {
      error = { ...error, roTelNumber: "Invalid telephone number." };
    }
    if (state.osEmail && !isEmailValid(state.osEmail)) {
      error = { ...error, osEmail: "Invalid email address." };
    }
    if (state.osTelNumber && !isNumberValid(state.osTelNumber)) {
      error = { ...error, osTelNumber: "Invalid telephone number." };
    }
    if (state.oEmail && !isEmailValid(state.oEmail)) {
      error = { ...error, oEmail: "Invalid email address." };
    }
    if (state.oTelNumber && !isNumberValid(state.oTelNumber)) {
      error = { ...error, oTelNumber: "Invalid telephone number." };
    }
    if (state.aEmail && !isEmailValid(state.aEmail)) {
      error = { ...error, aEmail: "Invalid email address." };
    }
    if (state.aTelNumber && !isNumberValid(state.aTelNumber)) {
      error = { ...error, aTelNumber: "Invalid telephone number." };
    }

    if (state.accNo && !validateAccountNo(state.accNo)) {
      error = { ...error, accNo: "Invalid Account Number." };
    }
    if (state.iban && !isIbanValid(state.iban)) {
      error = { ...error, iban: "Invalid IBAN number." };
    }
    if (state.sortCode && !isSortCodeValid(state.sortCode)) {
      error = { ...error, sortCode: "Invalid Sort Code." };
    }
    if (state.swiftCode && !isSwiftValid(state.swiftCode)) {
      error = { ...error, swiftCode: "Invalid SWIFT number." };
    }
    setState({ ...state, error });

    return Object.keys(error).length === 0;
  };
  return (
    <PageWrapper meta={meta} secure>
      <div className="h-app-profile-page-content">
        <Scrollable>
          <Form title="Update Profile">
            <div className="supplier-item">
              <Typography>
                <h2>Basic Company Details</h2>
              </Typography>
              <TextField
                placeholder="Company Registered Name"
                value={state.supplierName}
                onChange={(e) => handleChange("supplierName", e.target.value)}
                required
                label="Company Registered Name"
                name="supplierName"
                error={state.error.supplierName}
                helperText={state.error.supplierName}
              />
              <TextField
                placeholder="Trading Name"
                value={state.tradingName}
                onChange={(e) => handleChange("tradingName", e.target.value)}
                label="Trading Name"
                name="tradingName"
              />
              <TextField
                placeholder="Email Address"
                value={state.email}
                onChange={(e) => handleChange("email", e.target.value)}
                required
                label="Email Address"
                name="email"
                error={state.error.email}
                helperText={state.error.email}
              />
              <TextField
                placeholder="Website"
                value={state.website}
                onChange={(e) => handleChange("website", e.target.value)}
                label="Website"
                name="website"
              />
              <TextField
                placeholder="Minimum Order Value"
                value={state.mov}
                onChange={(e) => handleChange("mov", e.target.value)}
                required
                label="Minimum Order Value"
                type="number"
                name="mov"
              />
              <List
                multiple={true}
                options={counties.map((county: any) => county.county_name)}
                label="County Serviced"
                className="countyServiced"
                id="countyServiced"
                value={countyServiced}
                isOptionEqualToValue={(o, v) => o === v}
                onChange={(e, v) => handleChange("countyServiced", v)}
                disableClose={true}
              />

              <FileUpload
                label="Supplier Logo"
                className="file"
                value={{ display: "Supplier Logo", path: state.supplierLogo }}
                acceptedTypes={["png", "jpg"]}
                name="supplierLogo"
              />
              <Card sx={{ width: 300 }}>
                <CardContent>
                  <img
                    alt="Supplier Logo"
                    style={{
                      maxWidth: 250,
                      maxHeight: 250,
                      objectFit: "contain",
                      alignSelf: "center",
                    }}
                    src={state.supplierLogo}
                  />
                </CardContent>
              </Card>
              <Typography>
                <h2>Registered Office Contact</h2>
              </Typography>
              <TextField
                placeholder="Telephone Number"
                value={state.roTelNumber}
                onChange={(e) => handleChange("roTelNumber", e.target.value)}
                required
                label="Telephone Number"
                name="roTelNumber"
                error={state.error.roTelNumber}
                helperText={state.error.roTelNumber}
              />
              <TextField
                placeholder="Email"
                value={state.roEmail}
                onChange={(e) => handleChange("roEmail", e.target.value)}
                required
                label="Email Address"
                name="roEmail"
                error={state.error.roEmail}
                helperText={state.error.roEmail}
              />
              <Typography>
                <h2>Office Support Contact</h2>
              </Typography>
              <TextField
                placeholder="Telephone Number"
                value={state.osTelNumber}
                onChange={(e) => handleChange("osTelNumber", e.target.value)}
                required
                label="Telephone Number"
                name="osTelNumber"
                error={state.error.osTelNumber}
                helperText={state.error.osTelNumber}
              />
              <TextField
                placeholder="Email"
                value={state.osEmail}
                onChange={(e) => handleChange("osEmail", e.target.value)}
                required
                label="Email Address"
                name="osEmail"
                error={state.error.osEmail}
                helperText={state.error.osEmail}
              />
              <Typography>
                <h2>Orders Contact</h2>
              </Typography>
              <TextField
                placeholder="Contact Person"
                value={state.oContactPerson}
                onChange={(e) => handleChange("oContactPerson", e.target.value)}
                label="Contact Person"
                name="oContactPerson"
                error={state.error.oContactPerson}
                helperText={state.error.oContactPerson}
              />
              <TextField
                placeholder="Telephone Number"
                value={state.oTelNumber}
                onChange={(e) => handleChange("oTelNumber", e.target.value)}
                label="Telephone Number"
                name="oTelNumber"
                error={state.error.oTelNumber}
                helperText={state.error.oTelNumber}
              />
              <TextField
                placeholder="Email"
                value={state.oEmail}
                onChange={(e) => handleChange("oEmail", e.target.value)}
                label="Email Address"
                name="oEmail"
                error={state.error.oEmail}
                helperText={state.error.oEmail}
              />
              <Typography>
                <h2>Accounts Contact</h2>
              </Typography>
              <TextField
                placeholder="Contact Person"
                value={state.aContactPerson}
                onChange={(e) => handleChange("aContactPerson", e.target.value)}
                label="Contact Person"
                name="aContactPerson"
                error={state.error.aContactPerson}
                helperText={state.error.aContactPerson}
              />
              <TextField
                placeholder="Telephone Number"
                value={state.aTelNumber}
                onChange={(e) => handleChange("aTelNumber", e.target.value)}
                label="Telephone Number"
                name="aTelNumber"
                error={state.error.aTelNumber}
                helperText={state.error.aTelNumber}
              />
              <TextField
                placeholder="Email"
                value={state.aEmail}
                onChange={(e) => handleChange("aEmail", e.target.value)}
                label="Email Address"
                name="aEmail"
                error={state.error.aEmail}
                helperText={state.error.aEmail}
              />
              <Typography>
                <h2>Registered Business Address</h2>
              </Typography>
              <TextField
                placeholder="Address Line 1"
                value={state.addressLine1}
                onChange={(e) => handleChange("addressLine1", e.target.value)}
                required
                label="Address Line 1"
                name="addressLine1"
              />
              <TextField
                placeholder="Address Line 2"
                value={state.addressLine2}
                onChange={(e) => handleChange("addressLine2", e.target.value)}
                required
                label="Address Line 2"
                name="addressLine2"
              />
              <TextField
                placeholder="Address Line 3"
                value={state.addressLine3}
                onChange={(e) => handleChange("addressLine3", e.target.value)}
                required
                label="Address Line 3"
                name="addressLine3"
              />
              <TextField
                placeholder="Town"
                value={state.town}
                onChange={(e) => handleChange("town", e.target.value)}
                required
                label="Town"
                name="town"
                error={state.error.town}
                helperText={state.error.town}
              />
              <List
                options={counties.map((county: any) => county.county_name)}
                label={state.county}
                className="county"
                id="county"
                value={state.county}
                isOptionEqualToValue={(o, v) => o === v}
                onChange={(e, v) => handleChange("county", v)}
              />
              <TextField
                placeholder="Area Code"
                value={state.areaCode}
                onChange={(e) => handleChange("areaCode", e.target.value)}
                required
                label="Area Code"
                name="areaCode"
                error={state.error.areaCode}
                helperText={state.error.areaCode}
              />
              <FileUpload
                label="Proof of Address"
                className="file"
                value={{
                  display: "Proof of Address",
                  path: state.proofOfAddress,
                }}
                name="proofOfAddress"
              />
              <Typography>
                <h2>Company Details</h2>
              </Typography>
              <TextField
                placeholder="Company Registration Number"
                value={state.companyRegNo}
                onChange={(e) => handleChange("companyRegNo", e.target.value)}
                required
                label="Company Registration Number"
                name="companyRegNo"
                error={state.error.companyRegNo}
                helperText={state.error.companyRegNo}
              />
              <FileUpload
                label="Company Reg Document"
                className="file"
                value={{
                  display: "Company Registration Document",
                  path: state.regDocument,
                }}
                name="regDocument"
              />
              <TextField
                placeholder="Company VAT Registration Number"
                value={state.companyVATRegNo}
                onChange={(e) =>
                  handleChange("companyVATRegNo", e.target.value)
                }
                label="Company VAT Registration Number"
                name="companyVATRegNo"
                error={state.error.companyVATRegNo}
                helperText={state.error.companyVATRegNo}
              />
              <FileUpload
                label="VAT Reg Document"
                className="file"
                value={{
                  display: "VAT Registration Document",
                  path: state.vatRegDocument,
                }}
                name="vatRegDocument"
              />

              <TextField
                placeholder="Bank"
                value={state.bank}
                onChange={(e) => handleChange("bank", e.target.value)}
                label="Bank"
                name="bank"
              />
              <TextField
                placeholder="Branch Name"
                value={state.branchName}
                onChange={(e) => handleChange("branchName", e.target.value)}
                label="Branch Name"
                name="branchName"
              />
              <TextField
                placeholder="Branch Code"
                value={state.branchCode}
                onChange={(e) => handleChange("branchCode", e.target.value)}
                label="Branch Code"
                name="branchCode"
              />
              <TextField
                placeholder="Account Holder Name"
                value={state.accHolderName}
                onChange={(e) => handleChange("accHolderName", e.target.value)}
                label="Account Holder Name"
                name="accHolderName"
              />
              <TextField
                placeholder="Account Number"
                value={state.accNo}
                onChange={(e) => handleChange("accNo", e.target.value)}
                label="Account Holder Name"
                name="accNo"
                error={state.error.accNo}
              />
              <TextField
                placeholder="IBAN"
                value={state.iban}
                onChange={(e) => handleChange("iban", e.target.value)}
                label="IBAN"
                name="iban"
                error={state.error.iban}
              />
              <TextField
                placeholder="Sort Code"
                value={state.sortCode}
                onChange={(e) => handleChange("sortCode", e.target.value)}
                label="Sort Code"
                name="sortCode"
                error={state.error.sortCode}
              />
              <TextField
                placeholder="Swift Code"
                value={state.swiftCode}
                onChange={(e) => handleChange("swiftCode", e.target.value)}
                label="Swift Code"
                name="swiftCode"
                error={state.error.swiftCode}
              />
              <FileUpload
                label="Proof of Bank Account"
                className="file"
                value={{
                  display: "Proof of Bank Account",
                  path: state.bankAccProof,
                }}
              />
              <Typography>
                <h2>Supply And Trading Terms</h2>
              </Typography>
              <FileUpload
                label="General Supply and Trading Terms"
                className="file"
                value={{
                  display: "Supply and Trading Terms",
                  path: state.suppAndTradeDoc,
                }}
                name="suppAndTradeDoc"
              />
              <TextField
                placeholder="User Status"
                value={state.userStatus}
                onChange={(e) => handleChange("userStatus", e.target.value)}
                label="User Status"
                name="userStatus"
                readOnly
              />
              <div className="button-container">
                <Button
                  text={"Submit"}
                  primary
                  raised
                  onClick={handleUpdate}
                  working={state.submitting}
                />
              </div>
            </div>
          </Form>
        </Scrollable>
      </div>
    </PageWrapper>
  );
}
