import React from "react";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { ToolButton } from "../../../../components";
import { IToolButtonProps } from "../../../../interfaces";

export default function BackButton({ config }: IToolButtonProps) {
  const { id: moduleId } = config;

  return (
    <ToolButton
      text="Back"
      icon={faChevronLeft}
      path={`/${moduleId}`}
      primary
      raised
    />
  );
}
