import React from "react";
import "./_styles.scss";

interface LibraryCardProps {
  data?: any;
}
export default function LibraryCard({ data }: LibraryCardProps) {
  const { id, department } = data;

  return (
    <div className="module-a-library-card">
      <h3 color="#1D84B5">{department}</h3>
    </div>
  );
}
