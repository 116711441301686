import React from "react";
import data from "../../../../_data/modules/module1";
import "./_styles.scss";

interface HeadingProps {
  title: string;
}

export default function Heading({ title }: HeadingProps) {
  return <h1>{title}</h1>;
}
