import { Card, CardContent, Typography } from "@mui/material";
import React, { FC } from "react";
import { TextField } from "../fields";
import "./_styles.scss";

interface ProductModalProps {
  product: any;
}

const ProductModal: FC<ProductModalProps> = ({ product }) => {
  return (
    <div className="product-modal">
      {/* <div className="title">
        <h2>{product.title}</h2>
      </div> */}
      <Card
        sx={{
          width: 300,
          alignSelf: "center",
        }}
      >
        <CardContent>
          <img
            style={{ maxWidth: 250, maxHeight: 250, objectFit: "contain" }}
            src={product.image}
          />
        </CardContent>
      </Card>
      {/* <Card
        sx={{
          height: 150,
          alignSelf: "left",
        }}
      >
        <CardContent style={{ paddingBottom: 24 }}>
          <div className="float-parent-element">
            <div className="float-child-element">
              <h2>{product.title}</h2>
            </div>
            <div className="float-child-element">
              <span>
                {product.saleConfig.toLowerCase() == "case" ? (
                  <h3>€ {product.casePrice}</h3>
                ) : (
                  <h3>€ {product.unitPrice}</h3>
                )}
              </span>
            </div>
          </div>
        </CardContent>
      </Card>
      <Card>
        <CardContent style={{ paddingBottom: 24 }}>
          <div className="float-parent-element">
            <div className="float-child-element">
              <h2>Brand</h2>
            </div>
            <div className="float-child-element">
              <h3>{product.brand}</h3>
            </div>
          </div>
          <div className="float-parent-element">
            <div className="float-child-element">
              <h2>Unit Size</h2>
            </div>
            <div className="float-child-element">
              <h3>{product.unitMeasurement}</h3>
            </div>
          </div>
          <div className="float-parent-element">
            <div className="float-child-element">
              <h2>Sales Configuration</h2>
            </div>
            <div className="float-child-element">
              <h3>{product.saleConfig}</h3>
            </div>
          </div>
          <div className="float-parent-element">
            <div className="float-child-element">
              <h2>Units in a Case</h2>
            </div>
            <div className="float-child-element">
              <h3>{product.unitInCase}</h3>
            </div>
          </div>
        </CardContent>
      </Card> */}

      <TextField readOnly value={product.brand} label="Brand" />
      <TextField readOnly value={product.productName} label="Product Name" />
      <TextField readOnly value={product.categoryId} label="Category" />
      <TextField readOnly value={product.subCategory} label="Sub-Category" />
      <div className="field-group">
        <TextField
          readOnly
          value={product.unitOfMeasurement}
          label="Unit of Measure"
        />
        <TextField
          readOnly
          value={product.unitMeasurement}
          label="Unit Measurement"
        />
      </div>
      <TextField
        readOnly
        value={product.saleConfig}
        label="Sale Configuration"
      />
      <div className="field-group">
        <TextField readOnly value={product.productCode} label="Product Code" />
        <TextField readOnly value={product.unitBarcode} label="Unit Barcode" />
      </div>
      <TextField readOnly value={product.unitInCase} label="Units in Case" />
      {product.saleConfig.toLowerCase() == "case" && (
        <TextField readOnly value={product.caseBarcode} label="Case Barcode" />
      )}
      {product.saleConfig.toLowerCase() == "case" ? (
        <TextField readOnly value={product.casePrice} label="Case Price" />
      ) : (
        <TextField readOnly value={product.unitPrice} label="Unit Price" />
      )}
      <TextField
        readOnly
        value={product.productStatus}
        label="Product Status"
      />
    </div>
  );
};

export default ProductModal;
