import React from "react";
import { ToolbarContextEnum } from "../../enums";
import { IModuleConfig } from "../../interfaces";
import SearchBox from "../search-box/SearchBox";
import ToolSplitter from "./ToolSplitter";
import "./_styles.scss";

interface ToolbarProps {
  config?: IModuleConfig;
}

export default function Toolbar({ config }: ToolbarProps) {
  if (!config) return <div className="h-app-toolbar" />;

  const { context, item, toolbarItems, allowSearch, ...rest } = config;
  let itemsToRender: any[];

  switch (context) {
    case ToolbarContextEnum.Library:
      itemsToRender = toolbarItems?.library ?? [];
      break;
    case ToolbarContextEnum.ViewItem:
      itemsToRender = toolbarItems?.view ?? [];
      break;
    case ToolbarContextEnum.EditItem:
      itemsToRender = toolbarItems?.edit ?? [];
      break;
    case ToolbarContextEnum.CreateItem:
      itemsToRender = toolbarItems?.create ?? [];
      break;
    default:
      itemsToRender = [];
      break;
  }

  return (
    <div className="h-app-toolbar">
      {allowSearch && <SearchBox config={config} />}
      {itemsToRender.map((ToolItem, i) => {
        if (ToolItem === "|")
          return <ToolSplitter key={`tool-splitter-${i}`} />;

        return <ToolItem key={`tool-item-${i}`} config={config} item={item} />;
      })}
    </div>
  );
}
