import { createContext, useContext } from "react";
import { IUserContext } from "../interfaces";
import { Nullable } from "../types";

let AuthContext = createContext<Nullable<IUserContext>>(null);

function useAuthentication() {
  return useContext<Nullable<IUserContext>>(AuthContext);
}

export { AuthContext, useAuthentication };
