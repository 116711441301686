import React, { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { TextField } from "../../../../components/fields";
import Form from "../../../../components/form/Form";
import { useStateReducer, useSubscription } from "../../../../hooks";
import { IModuleConfig } from "../../../../interfaces";
import { validateObject } from "../../../../utilities";
import Heading from "../heading/Heading";

interface ViewItemProps {
  data?: any;
  config: IModuleConfig;
}

export default function ViewItem({ config, data }: ViewItemProps) {
  const [state, setState] = useStateReducer({
    ...data,
  });
  const { services } = config;
  var stateRef = useRef(state);
  var navigate = useNavigate();

  const handleChange = (prop: string, value: any) => {
    setState({ ...state, [prop]: value, errorHightlight: false });
  };
  useEffect(() => {
    stateRef.current = state;
  }, [state]);

  useSubscription("category", async () => {
    await services.update(stateRef.current);
    navigate(`/${config.id}`);
  });

  const handleSubChange = (index: number, value: any) => {
    var subDepartments = state.subDepartment;
    subDepartments[index] = value;

    setState({ subDepartment: subDepartments });
  };

  const checkToRemove = (index: number, value: any) => {
    var subDepartments = state.subDepartment;
    subDepartments[index] = value;

    if (!value) {
      delete subDepartments[index];
    }

    setState({ ...state, subDepartment: subDepartments });
  };

  const addSubDepartment = (e: any) => {
    if (!e.target.value) {
      return;
    }
    var subDepartments = state.subDepartment;
    subDepartments.push(e.target.value);
    e.target.value = "";
    setState({ subDepartment: subDepartments });
  };

  const hasError = (prop: string) => (errors[prop] ? true : false);

  const errors = validateObject(
    {
      department: { regex: /.+/, message: "This field is required." },
      subdepartment: { regex: /.+/, message: "This field is required." },
    },
    state
  );

  return (
    <div className="category-item">
      <Heading {...data} />
      <Form title="Categories">
        <TextField
          label="Department"
          onChange={(e) => handleChange("department", e.target.value)}
          value={state.department}
          error={hasError("department")}
        />
        <Heading title="subDeparments" lastname="" />
        {state.subDepartment?.map((sub: any, index: number) => (
          <TextField
            label="Sub-Department"
            onChange={(e) => handleSubChange(index, e.target.value)}
            onBlur={(e) => checkToRemove(index, e.target.value)}
            value={sub}
            error={hasError("subDepartment")}
          />
        ))}
        <TextField
          label="New Sub-Department"
          onBlur={(e) => addSubDepartment(e)}
          error={hasError("subDepartment")}
        />
      </Form>
    </div>
  );
}
