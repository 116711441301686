import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import "./_styles.scss";

interface ErrorProps {
  message?: string;
}

export default function Error({ message }: ErrorProps) {
  return (
    <div className="h-app-error">
      <FontAwesomeIcon icon={faExclamationTriangle} />
      {message}
    </div>
  );
}
