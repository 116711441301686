import React from "react";
import { faCancel } from "@fortawesome/free-solid-svg-icons";
import { ToolButton } from "../../../../components";
import { IToolButtonProps } from "../../../../interfaces";

export default function CancelButton({ config, item }: IToolButtonProps) {
  const { id: moduleId } = config;
  const { id: itemId, isNew } = item;

  return (
    <ToolButton
      text="Cancel"
      icon={faCancel}
      path={isNew ? `/${moduleId}` : `/${moduleId}/${itemId}`}
    />
  );
}
