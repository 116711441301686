import React, { useEffect } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { StateTopicEnum } from "./enums";
import {
  AuthContext,
  publish,
  useAuthentication,
  useStateReducer,
} from "./hooks";
import { IUserContext } from "./interfaces";
import { global } from "./_config";

interface ChildrenProps {
  children?: React.ReactNode;
}

interface RequiredChildrenProps {
  children: any;
}

export default function AuthProvider({ children }: ChildrenProps) {
  const [state, setState] = useStateReducer({ user: null, loading: true });
  const {
    signInUser,
    signOutUser,
    checkAuthentication,
    signUpUser,
    getUserInfo,
  } = global.services;
  const { extractUserFromAuthResponse } = global.utilities;
  const { user, loading } = state;

  const signIn = async (
    username: string,
    password: string
  ): Promise<Object> => {
    // Known issue here somewhere
    const res = await signInUser({ username, password });
    const user = extractUserFromAuthResponse(res);
    if (user.userStatus === "Accepted") {
      setState({ user });
      publish(StateTopicEnum.User, res);
    }

    return user;
  };

  const signOut = async () => {
    await signOutUser();
    setState({ user: null });
    publish(StateTopicEnum.User, null);
  };

  const signUp = async (registerDetails: any) => {
    const res = await signUpUser(registerDetails);
    const user = extractUserFromAuthResponse(res);
    if (user.userStatus === "Accepted") {
      setState({ user });
      publish(StateTopicEnum.User, res);
    }

    return user;
  };

  const context: IUserContext = { user, signIn, signOut, signUp };

  useEffect(() => {
    const checkAuth = async () => {
      const res = await getUserInfo();
      const user = extractUserFromAuthResponse(res);

      setState({ user, loading: false });
      publish(StateTopicEnum.User, res);
    };

    checkAuth();
  }, [checkAuthentication, extractUserFromAuthResponse, setState, getUserInfo]);

  if (loading) return <></>;

  return (
    <AuthContext.Provider value={context}>{children}</AuthContext.Provider>
  );
}

export function Secure({ children }: RequiredChildrenProps) {
  const auth = useAuthentication();
  const location = useLocation();
  if (!auth?.user)
    return <Navigate to="/login" state={{ from: location }} replace />;

  return children;
}
