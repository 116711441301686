import React from "react";
import { NumberField, TextField } from "../../../../components/fields";
import Form from "../../../../components/form/Form";
import "./_styles.scss";
import Heading from "../heading/Heading";
import { Card, CardContent } from "@mui/material";

interface ViewItemProps {
  data: any;
}

export default function ViewItem({ data }: ViewItemProps) {
  return (
    <div className="product-item">
      <div className="sticky">
        <Heading lin {...data} />
      </div>
      <Form title="Product Details">
        <TextField label="Brand" value={data.brand} readOnly />
        <TextField
          label="Product Title/Name"
          value={data.productName}
          readOnly
        />
        <TextField label="Category" value={data.categoryId} readOnly />
        <TextField label="Sub-Category" value={data.subCategory} readOnly />
        <TextField
          label="Unit of Measure"
          value={data.unitOfMeasurement}
          readOnly
        />
        <TextField label="Unit Size" value={data.unitMeasurement} readOnly />
        <TextField label="Sales Configuration" value={data.saleConfig} />
        <TextField label="Product Code" value={data.productCode} readOnly />
        <TextField label="Unit Barcode" value={data.unitBarcode} readOnly />
        {data.saleConfig === "Case" ? (
          <>
            <TextField
              label="Units In a Case"
              value={data.unitInCase}
              readOnly
            />
            <TextField label="Case Barcode" value={data.caseBarcode} readOnly />
            <NumberField
              label="Case Price"
              value={data.casePrice}
              readOnly
              startAdornment="€ "
            />
          </>
        ) : (
          <>
            <NumberField
              label="Unit Price"
              value={data.unitPrice}
              readOnly
              startAdornment="€ "
            />
          </>
        )}
        <TextField label="Product Status" value={data.productStatus} readOnly />
        <Card sx={{ width: 300, alignSelf: "Center" }}>
          <CardContent>
            <img
              style={{
                maxWidth: 250,
                maxHeight: 250,
                objectFit: "contain",
              }}
              src={data.image}
            />
          </CardContent>
        </Card>
      </Form>
    </div>
  );
}
