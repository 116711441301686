import { Console } from "console";
import { v4 as uuidv4 } from "uuid";
import {
  IDeleteSingleArgs,
  IGetSingleArgs,
  IPageAndSearchArgs,
  ISearchArgs,
  IUpdateSingleArgs,
} from "../../../interfaces";
import { asyncify } from "../../../utilities";
import { goFetch } from "../../../utilities/goFetch";
import dummyData from "./_dummyData";

class Service {
  async getByPage({ pageNo, search }: IPageAndSearchArgs) {
    const pageSize = 10;
    var response: any = await goFetch(`user/buyers`).get();
    var jsonResponse = await response.json();
    if ((jsonResponse.statusCode = 200)) {
      var buyers = jsonResponse.buyers;
      buyers = search
        ? buyers.filter((d: any) => `${d.name}`.includes(search))
        : buyers;

      const intPageCount = Math.floor(buyers.length / pageSize);
      const remPageCount = buyers.length - intPageCount * pageSize > 0 ? 1 : 0;

      if (jsonResponse.success) {
        return await asyncify(
          () => ({
            pageNo,
            pageSize: pageSize,
            pageCount: intPageCount + remPageCount,
            itemCount: buyers.length,
            data: buyers.slice((pageNo - 1) * pageSize, pageNo * pageSize),
          }),
          200
        );
      }
    }
  }

  async getSingle({ key }: IGetSingleArgs) {
    if (key.length > 0) {
      var response: any = await goFetch(`user?id=${key}`)
        .withHeaders({ "Content-Type": "application/json" })
        .post();
      var jsonResponse = await response.json();
      if (jsonResponse.success) {
        return jsonResponse.users;
      } else if (jsonResponse.message === "Invalid Id") {
        return {};
      }
    } else {
      return await asyncify(
        () => dummyData.filter((d) => d.id === key)[0],
        200
      );
    }
  }

  async search({ search }: ISearchArgs) {
    return await asyncify(
      () => dummyData.filter((d: any) => new RegExp(search).test(d.name)),
      200
    );
  }

  async create() {
    const id = uuidv4();
    return id;
  }

  async update({ key, ...rest }: IUpdateSingleArgs) {
    const res: any = await goFetch(`user/update?id=${rest.id}`)
      .withBody(rest)
      .post();
  }

  async delete({ key }: IDeleteSingleArgs) {
    return await asyncify(async () => {
      const toDelete = await this.getSingle({ key });
      const index = dummyData.indexOf(toDelete);
      dummyData.splice(index, 1);
    }, 200);
  }
}

export default new Service();
