import { v4 as uuidv4 } from "uuid";
import {
  IDeleteSingleArgs,
  IGetSingleArgs,
  IPageAndSearchArgs,
  ISearchArgs,
  IUpdateSingleArgs,
} from "../../../interfaces";
import { asyncify } from "../../../utilities";
import { goFetch } from "../../../utilities/goFetch";
import dummyData from "./_dummyData";

class Service {
  async getByPage({ search, pageNo }: IPageAndSearchArgs) {
    var response: any = await goFetch(
      `order/supplier?id=${localStorage.getItem("h-user-id")}`
    ).get();
    var jsonResponse = await response.json();
    const data = search
      ? jsonResponse.order.filter((d: any) =>
          `${d.orderId.toLowerCase()}`.includes(search.toLowerCase())
        )
      : jsonResponse.order;
    const pageSize = 20;
    const intPageCount =
      Math.floor((data.length === "" ? 1 : data.length) / pageSize) ?? 1;
    const remPageCount =
      (data.length === "" ? 1 : data.length) - intPageCount / pageSize > 0
        ? pageNo
        : 0;

    if (jsonResponse.success) {
      return await asyncify(
        () => ({
          pageNo,
          pageSize: pageSize,
          pageCount: intPageCount + remPageCount,
          itemCount: data.length,
          data: data.slice((pageNo - 1) * pageSize, pageNo * pageSize),
        }),
        200
      );
    }
  }

  async getSingle({ key }: IGetSingleArgs) {
    if (key.length > 0) {
      var response: any = await goFetch(`order?id=${key}`)
        .withHeaders({ "Content-Type": "application/json" })
        .get();
      var jsonResponse = await response.json();
      if (jsonResponse.success) {
        return jsonResponse.order;
      }
    } else {
      return await asyncify(
        () => dummyData.filter((d) => d.id === key)[0],
        200
      );
    }
  }

  async search({ search }: ISearchArgs) {
    return await asyncify(
      () => dummyData.filter((d) => new RegExp(search).test(d.OrderId)),
      200
    );
  }

  async create() {
    const id = uuidv4();
    return id;
  }

  async update({ key, ...rest }: IUpdateSingleArgs) {
    console.log(rest);
    await goFetch(`order/update`)
      .withHeaders({ "Content-Type": "application/json" })
      .withBody(rest)
      .post();
  }

  async delete({ key }: IDeleteSingleArgs) {
    return await asyncify(async () => {
      const toDelete = await this.getSingle({ key });
      const index = dummyData.indexOf(toDelete);
      dummyData.splice(index, 1);
    }, 200);
  }
}

export default new Service();
