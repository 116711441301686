enum DataFieldTypeEnum {
  Text = "text",
  Integer = "int",
  Float = "float",
  Boolean = "bool",
  Date = "date",
  Reference = "ref",
}

export default DataFieldTypeEnum;
