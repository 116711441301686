import { v4 as uuidv4 } from "uuid";

const dummyLibraryData = [...Array(1000000).keys()].map((v) => ({
  id: uuidv4(),
  firstname: `Client ${v + 1}`,
  lastname: `Surname for client`,
  dob: new Date(1983, 11, 6),
  isAlive: v % 2 === 0 ? true : false,
  gender: v % 3 === 0 ? "male" : v % 3 === 1 ? "female" : "trans",
  country: "za",
  isNew: false,
}));

export default dummyLibraryData;
