import React from "react";
import {
  FileUpload,
  InstructionWrapper,
  List,
  TextField,
} from "../../../../components/fields";
import Form from "../../../../components/form/Form";
import "./_styles.scss";
import Heading from "../heading/Heading";
import { LibraryCard } from "../../../orders-module/components";
import { Card, CardContent } from "@mui/material";

interface ViewItemProps {
  data: any;
}

export default function ViewItem({ data }: ViewItemProps) {
  return (
    <div className="brand-item">
      <Heading {...data} />
      <Form title="Brand Details">
        <TextField label="Name" value={data.name} readOnly />
        <Card sx={{ width: 300 }}>
          <CardContent>
            <img
              style={{
                maxWidth: 250,
                maxHeight: 250,
                objectFit: "contain",
                alignSelf: "center",
              }}
              src={data.image}
            />
          </CardContent>
        </Card>
      </Form>
    </div>
  );
}
