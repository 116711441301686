import React from "react";
import "./_styles.scss";

interface LibraryCardProps {
  data?: any;
}
export default function LibraryCard({ data }: LibraryCardProps) {
  const { id, name} = data;

  return (
    <div className="module-a-library-card">
      <h3 color="#1D84B5">
        {name} 
      </h3>
    </div>
  );
}
