import React from "react";
import { useEffect, useState } from "react";
import { Link, NavLink, useNavigate, useLocation } from "react-router-dom";
import { ButtonBase } from "@mui/material";
import { classNameBuilder } from "../../utilities";
import "./_styles.scss";
import { Nullable } from "../../types";

interface ButtonWrapperProps {
  className?: string;
  children?: React.ReactNode;
}

const ButtonWrapper = ({ className, children }: ButtonWrapperProps) => (
  <div className={className}>{children}</div>
);

interface ClickableProps {
  to?: string;
  replace?: boolean;
  isNavLink?: boolean;
  data?: any;
  onClick?: Function;
  className?: string;
  children?: React.ReactNode;
}

export default function Clickable({
  to,
  replace,
  isNavLink,
  data,
  onClick,
  className,
  children,
}: ClickableProps) {
  const [classes, setClasses] = useState(
    classNameBuilder("h-app-clickable", className ?? "")
  );
  const [active, setActive] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  let timeout: Nullable<NodeJS.Timeout> = null;

  useEffect(() => {
    setClasses(
      classNameBuilder(
        "h-app-clickable",
        className ?? "",
        active ? "clicked" : ""
      )
    );
  }, [active, className]);

  useEffect(() => {
    if (timeout) clearTimeout(timeout);
  }, [timeout]);

  const Component = to ? (isNavLink ? NavLink : Link) : ButtonWrapper;

  const handleClick = (e: any) => {
    if (onClick) {
      const result = onClick(e, data);

      if (result === false) {
        e.preventDefault();
        return;
      }
    }

    if (location?.pathname === to) return;

    if (e.defaultPrevented) return;

    e.preventDefault();

    timeout = setTimeout(() => {
      setActive(false);
      navigate(to ?? "", { replace: replace ?? false, state: data });
    }, 0);

    setActive(true);
  };

  return (
    <Component className={classes} to={to ?? ""} state={data}>
      <ButtonBase onClick={handleClick}>{children}</ButtonBase>
    </Component>
  );
}
