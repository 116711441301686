import React from "react";
import { TextField } from "../../../components/fields";
import "./_styles.scss";

export default function ProfilePage() {
  return (
    <div className="profile-page">
      <TextField label="Name" value={""} readOnly />
      <TextField label="Surname" value={""} readOnly />
      <TextField label="Email" value={""} readOnly />
      <TextField label="Phone Number" value={""} readOnly />
      <TextField label="Contact Person" value={""} readOnly />
    </div>
  );
}
