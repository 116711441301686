import { v4 as uuidv4 } from "uuid";
import {
  IDeleteSingleArgs,
  IGetSingleArgs,
  IPageAndSearchArgs,
  ISearchArgs,
  IUpdateSingleArgs,
} from "../../../interfaces";
import { asyncify } from "../../../utilities";
import { goFetch } from "../../../utilities/goFetch";
import dummyData from "./productModel";

class ProductService {
  async getByPage({ pageNo, search }: IPageAndSearchArgs) {
    const pageSize = 10;
    var response: any = await goFetch("product/all")
      .withQueryParameters({
        pageSize: pageSize,
        page: pageNo,
      })
      .withHeaders({ "Content-Type": "application/json" })
      .get();
    const jsonResponse = await response.json();
    if (jsonResponse.success) {
      var products = jsonResponse.products;
      const data = search
        ? products.filter((d: any) =>
            `${d.title.toLowerCase()}`.includes(search.toLowerCase())
          )
        : products;

      const intPageCount = Math.floor(data.length / pageSize);
      const remPageCount =
        data.length - intPageCount / pageSize > 0 ? pageNo : 0;
      return await asyncify(
        () => ({
          pageNo: pageNo,
          pageSize: pageSize,
          pageCount: remPageCount + intPageCount,
          itemCount: data.length,
          data: data.sort((a: any, b: any) => (a.title > b.title ? 1 : -1)),
        }),
        200
      );
    }
  }

  async getSingle({ key }: IGetSingleArgs) {
    if (key.length > 0) {
      var response: any = await goFetch(`product?id=${key}`)
        .withHeaders({ "Content-Type": "application/json" })
        .get();
      var jsonResponse = await response.json();

      if (jsonResponse.success) {
        return jsonResponse.products;
      }
    } else {
      return await asyncify(
        () => dummyData.filter((d) => d.id === key)[0],
        200
      );
    }
  }

  async create() {
    const id = uuidv4();
    return id;
  }

  async update({ key, ...rest }: IUpdateSingleArgs) {
    await goFetch(`product/update`)
      .withHeaders({ "Content-Type": "application/json" })
      .withBody(rest)
      .post();
  }

  async search({ search }: ISearchArgs) {
    var response: any = await goFetch(`product/search/${search}`)
      .withHeaders({ "Content-Type": "application/json" })
      .get();
    var jsonResponse = await response.json();
    return await asyncify(
      () => jsonResponse.products.filter((d: any) => search === d.title),
      200
    );
  }

  async delete({ key }: IDeleteSingleArgs) {
    await goFetch("product/delete")
      .withQueryParameters({
        id: key,
      })
      .withHeaders({ "Content-Type": "application/json" })
      .post();
  }
}

export default new ProductService();
