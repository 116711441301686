import React from 'react';
import { useLocation } from 'react-router-dom';
import getQueryParameter from '../../../../utilities/getQueryParameter';
import { EditItem, ViewItem } from '../';
import { useEffect } from 'react';
import { INavigableComponent } from '../../../../interfaces';
import { ToolbarContextEnum } from '../../../../enums';

interface ItemPageProps {
  config: any;
  data: any;
}

export default function ItemPage({
  config,
  data,
  setBreadcrumbs,
  setToolbarConfig,
}: ItemPageProps & INavigableComponent) {
  const location = useLocation();
  const mode = getQueryParameter(location.search, 'mode');

  useEffect(() => {
    let context;

    switch (mode) {
      case 'edit':
        context = ToolbarContextEnum.EditItem;
        break;
      default:
        context = ToolbarContextEnum.ViewItem;
        break;
    }

    setToolbarConfig?.({ ...config, context, item: data });
  }, [mode]);

  switch (mode) {
    case 'edit':
      return <EditItem data={data} />;
    default:
      return <ViewItem data={data} />;
  }
}
