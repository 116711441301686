import React from "react";
import "./_styles.scss";

interface HeadingProps {
  supplierName: string;
  lastname: string;
}

export default function Heading({ supplierName, lastname }: HeadingProps) {
  return <h1>{supplierName}</h1>;
}
