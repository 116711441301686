import React from "react";
import { useAuthentication } from "../../../../hooks";
import { global } from "../../../../_config";
import "./_styles.scss";
import { faBox } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface LibraryCardProps {
  data?: any;
}
export default function LibraryCard({ data }: LibraryCardProps) {
  const { id, title, unitPrice, casePrice } = data;
  const auth = useAuthentication();
  const { extractUserFromAuthResponse } = global.utilities;
  const user = extractUserFromAuthResponse(auth?.user);
  var supplier = user.id;
  data.supplierid = supplier;

  return (
    <div className="module-p-library-card">
      <div>
        <div className="icon-container">
          <FontAwesomeIcon icon={faBox} />
        </div>
      </div>
      <div>
        <h3 color="#1D84B5">{title}</h3>
        {data.saleConfig === "Unit" ? (
          <>
            <span>€ {unitPrice}</span>
          </>
        ) : (
          <>
            <span>€ {casePrice}</span>
          </>
        )}
      </div>
      {data.productStatus !== null || data.productStatus !== "" ? (
        <>
          <div>
            {data.productStatus === "Active" ? (
              <>
                <span className="badgeA">{data.productStatus}</span>
              </>
            ) : (
              <>
                <span className="badgeB">{data.productStatus}</span>
              </>
            )}
          </div>
        </>
      ) : (
        <></>
      )}
    </div>
  );
}
