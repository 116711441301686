import React from "react";
import { TextField } from "../../../../components/fields";
import Form from "../../../../components/form/Form";
import "./_styles.scss";
import Heading from "../heading/Heading";
import { Typography } from "@mui/material";
import Button from "../../../../components/button/Button";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { asyncify, downloadBase64File } from "../../../../utilities";
import { hideAlert, showAlert } from "../../../../components/alert/Alert";
import { AlertTypeEnum } from "../../../../enums";

interface ViewItemProps {
  data: any;
}

export default function ViewItem({ data }: ViewItemProps) {
  const errorAlert = () => {
    showAlert({
      content: "No File to download.",
      options: {
        actions: [
          {
            text: "OK",
            onClick: hideAlert,
            primary: true,
          },
        ],
        type: AlertTypeEnum.Error,
      },
    });
  };
  return (
    <div className="supplier-item">
      <div className="item-header">
        <Heading lin {...data} />
      </div>
      <Form>
        <Typography>
          <h2>Basic Company Details</h2>
        </Typography>
        <TextField
          label="Company Registered Name"
          value={data.supplierName}
          readOnly
        />
        <TextField label="Trading Name" value={data.tradingName} readOnly />
        <TextField label="Email " value={data.email} readOnly />
        <TextField label="Website" value={data.website} readOnly />
        <TextField label="Minimum Order Value " value={data.mov} readOnly />
        <Typography>
          <h2>Registered Office Contact</h2>
        </Typography>
        <TextField label="Telephone Number" value={data.roTelNumber} readOnly />
        <TextField label="Email " value={data.roEmail} readOnly />
        <Typography>
          <h2>Office Support Contact</h2>
        </Typography>
        <TextField label="Telephone Number" value={data.osTelNumber} readOnly />
        <TextField label="Email " value={data.osEmail} readOnly />
        <Typography>
          <h2>Orders Contact</h2>
        </Typography>
        <TextField
          label="Contact Person"
          value={data.oContactPerson}
          readOnly
        />
        <TextField label="Telephone Number" value={data.oTelNumber} readOnly />
        <TextField label="Email " value={data.oEmail} readOnly />
        <Typography>
          <h2>Accounts Contact</h2>
        </Typography>{" "}
        <TextField
          label="Contact Person"
          value={data.aContactPerson}
          readOnly
        />
        <TextField label="Telephone Number" value={data.aTelNumber} readOnly />
        <TextField label="Email " value={data.aEmail} readOnly />
        <Typography>
          <h2>Registered Business Address</h2>
        </Typography>
        <TextField label="Address Line 1" value={data.addressLine1} readOnly />
        <TextField label="Address Line 2" value={data.addressLine2} readOnly />
        <TextField label="Address Line 3" value={data.addressLine3} readOnly />
        <TextField label="Town" value={data.town} readOnly />
        <TextField label="County" value={data.county} readOnly />
        <TextField label="Area Code" value={data.areaCode} readOnly />
        <TextField
          label="Proof of Address"
          value={data.proofOfAddress === "" ? "No File" : "Proof of Address"}
          readOnly
          name="proofOfAddress"
          endAdornment={
            <Button
              icon={faDownload}
              primary
              onClick={(_) =>
                asyncify(
                  () =>
                    data.proofOfAddress === "" || data.proofOfAddress === null
                      ? errorAlert()
                      : downloadBase64File(
                          data.proofOfAddress,
                          "ProofOfAddress.pdf"
                        ),
                  0
                )
              }
              className="clear-button"
            />
          }
        />
        <Typography>
          <h2>Company Details</h2>
        </Typography>
        <TextField
          label="Company Registration Number"
          value={data.companyRegNo}
          readOnly
        />
        <TextField
          label="Company Registration Document"
          value={
            data.regDocument === ""
              ? "No File"
              : "Company Registration Document"
          }
          readOnly
          name="regDocument"
          endAdornment={
            <Button
              icon={faDownload}
              primary
              onClick={(_) =>
                asyncify(
                  () =>
                    data.regDocument === "" || data.regDocument === null
                      ? errorAlert()
                      : downloadBase64File(data.regDocument, "RegDocument.pdf"),
                  0
                )
              }
              className="clear-button"
            />
          }
        />
        <TextField
          label="Company VAT Registration Number"
          value={data.companyVATRegNo}
          readOnly
        />
        <TextField
          label="Company VAT Registration Document"
          value={
            data.vatRegDocument === ""
              ? "No File"
              : "Company VAT Registration Document"
          }
          readOnly
          name="vatRegDocument"
          endAdornment={
            <Button
              icon={faDownload}
              primary
              onClick={(_) =>
                asyncify(
                  () =>
                    data.vatRegDocument === "" || data.vatRegDocument === null
                      ? errorAlert()
                      : downloadBase64File(
                          data.vatRegDocument,
                          "VatRegDocument.pdf"
                        ),
                  0
                )
              }
              className="clear-button"
            />
          }
        />
        <Typography>
          <h2>Company Bank Details</h2>
        </Typography>
        <TextField label="Bank" value={data.bank} readOnly />
        <TextField label="Branch Name" value={data.branchName} readOnly />
        <TextField label="Branch Code" value={data.branchCode} readOnly />
        <TextField
          label="Account Holder Name"
          value={data.accHolderName}
          readOnly
        />
        <TextField label="Account Number" value={data.accNo} readOnly />
        <TextField label="IBAN" value={data.iban} readOnly />
        <TextField label="Sort Code" value={data.sortCode} readOnly />
        <TextField label="SWIFT Code" value={data.swiftCode} readOnly />
        <TextField
          label="Bank Account Proof"
          value={data.bankAccProof === "" ? "No File" : "Bank Account Proof"}
          readOnly
          name="bankAccProof"
          endAdornment={
            <Button
              icon={faDownload}
              primary
              onClick={(_) =>
                asyncify(
                  () =>
                    data.bankAccProof === "" || data.bankAccProof === null
                      ? errorAlert()
                      : downloadBase64File(
                          data.bankAccProof,
                          "BankAccProof.pdf"
                        ),
                  0
                )
              }
              className="clear-button"
            />
          }
        />
        <Typography>
          <h2>Supply And Trade Details</h2>
        </Typography>
        <TextField
          label="Supply And Trade Doc"
          value={
            data.suppAndTradeDoc === ""
              ? "No File"
              : "Supply And Trade Document"
          }
          readOnly
          name="suppAndTradeDoc"
          endAdornment={
            <Button
              icon={faDownload}
              primary
              onClick={(_) =>
                asyncify(
                  () =>
                    data.suppAndTradeDoc === "" || data.suppAndTradeDoc === null
                      ? errorAlert()
                      : downloadBase64File(
                          data.suppAndTradeDoc,
                          "SupplyAndTradeDoc.pdf"
                        ),
                  0
                )
              }
              className="clear-button"
            />
          }
        />
      </Form>
    </div>
  );
}
