import React, { useState } from "react";
import { Checkbox, List, TextField } from "../../../../components/fields";
import Form from "../../../../components/form/Form";
import { publish, useStateReducer } from "../../../../hooks";
import { validateObject } from "../../../../utilities";
import Heading from "../heading/Heading";
import AutoComplete, {
  AutoCompleteOption,
} from "../../../../components/fields/AutoComplete";
import Moment from "moment";
import { Grid } from "@mui/material";
import { stat } from "fs";
import { BoxProps } from "@mui/system";

const statuses = [
  { code: "p", name: "Pending" },
  { code: "t", name: "Processing" },
  { code: "d", name: "Dispatched" },
  { code: "c", name: "Cancelled" },
];
interface ViewItemProps {
  data?: any;
}

export default function ViewItem({ data }: ViewItemProps) {
  const [state, setState] = useStateReducer({
    ...data,
  });
  const [checked, setChecked] = useState();

  const handleChange = (prop: string, value: any) => {
    setState({ [prop]: value, errorHightlight: false });

    publish("order", { ...state, [prop]: value });
  };
  const handleCheckChange = (value: any) => {
    console.log(value);
    setChecked(value);
  };
  const handleOIChange = (orderItems: any, index: number, quantity: string) => {
    const array = orderItems.map((e: any, i: any) => {
      var qty = Number(quantity);
      if (i === index && qty <= e.quantity) {
        return { ...e, acceptedQuantity: qty };
      } else return e;
    });
    handleChange("orderItems", array);
  };

  const hasError = (prop: string) => (errors[prop] ? true : false);

  const errors = validateObject(
    {
      orderStatus: { regex: /.+/, message: "This field is required." },
    },
    state
  );
  var newDate = state.createdAt;
  newDate = Moment().format("DD/MM/YYYY");

  const getProducts = (id: string, products: any) => {
    return products.filter((e: any) => e.id === id)[0];
  };

  return (
    <div className="product-catalogue">
      <div style={{ padding: "0 20px" }}>
        <Heading {...data} />
      </div>
      <Form title="Order Details">
        <TextField label="Order Number" value={state.orderId} readOnly />
        <TextField
          label="Order Date"
          placeholder={newDate}
          value={newDate}
          readOnly
        />
        <AutoComplete
          options={statuses.map(
            (option) => new AutoCompleteOption(option.name, option.name)
          )}
          label="Order Status"
          prop="orderStatus"
          onChange={handleChange}
          value={state.orderStatus}
          error={hasError("orderStatus")}
        />
      </Form>
      <Form title="Order Total">
        <TextField
          label="Order Total"
          value={state.orderTotal}
          startAdornment="€ "
          readOnly
        />
      </Form>
      <Form title="Products">
        <br />
        <div className="card-body">
          <Grid container spacing={2}>
            <Grid item xs={3.8}>
              <div style={{ padding: "10px", display: "block" }}>
                <span>Product</span>
              </div>
            </Grid>

            <Grid item xs={2}>
              <div style={{ padding: "10px", display: "block" }}>
                <span>Quantity Ordered</span>
              </div>
            </Grid>
            <Grid item xs={3}>
              <div style={{ padding: "10px", display: "block" }}>
                <span>Quantity Available</span>
              </div>
            </Grid>
            <Grid item xs={2}>
              <div style={{ padding: "10px", display: "block" }}>
                <span>Product Total</span>
              </div>
            </Grid>
            <Grid item xs={1}>
              <div style={{ padding: "10px", display: "block" }}>
                <Checkbox
                  label=""
                  value={checked}
                  onChange={(a, v) => {
                    handleCheckChange(v);
                  }}
                />
              </div>
            </Grid>
          </Grid>
        </div>
        {state.orderItems.map((orderItem: any, index: any) => {
          var product = getProducts(orderItem.productId, state.products);
          return (
            <div className="card-body">
              <Grid container spacing={2} key={`grid-${index}`}>
                <Grid item xs={3.8}>
                  <div style={{ padding: "10px", display: "block" }}>
                    <div className="field-wrap">{product?.title}</div>
                  </div>
                </Grid>

                <Grid item xs={2}>
                  <div style={{ padding: "10px", display: "block" }}>
                    <div className="field-wrap">{orderItem.quantity}</div>
                  </div>
                </Grid>
                <Grid item xs={3} key={`grid-${index}`}>
                  <div style={{ padding: "10px", display: "block" }}>
                    <div className="field-wrap">
                      <TextField
                        type={"number"}
                        label="Quantity Available"
                        value={
                          checked
                            ? orderItem.quantity
                            : orderItem.acceptedQuantity
                        }
                        onChange={(e) =>
                          handleOIChange(
                            state.orderItems,
                            index,
                            e.target.value
                          )
                        }
                      />
                    </div>
                  </div>
                </Grid>
                <Grid item xs={2}>
                  <div style={{ padding: "10px", display: "block" }}>
                    <div className="field-wrap">
                      € {orderItem.total.toFixed(2)}
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>
          );
        })}
      </Form>
    </div>
  );
}
