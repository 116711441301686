import React, { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { FileUpload, TextField } from "../../../../components/fields";
import Form from "../../../../components/form/Form";
import { publish, useStateReducer, useSubscription } from "../../../../hooks";
import { IModuleConfig } from "../../../../interfaces";
import { validateObject } from "../../../../utilities";
import Heading from "../heading/Heading";

interface EditItemProps {
  data?: any;
  config: IModuleConfig;
}

export default function EditItem({ config, data }: EditItemProps) {
  const [state, setState] = useStateReducer({
    ...data,
  });
  const { services } = config;
  var stateRef = useRef(state);
  var navigate = useNavigate();
  const handleChange = (prop: string, value: any) => {
    setState({ ...state, [prop]: value, errorHightlight: false });
  };

  useEffect(() => {
    stateRef.current = state;
  }, [state]);

  useSubscription("brands", async () => {
    await services.update(stateRef.current);
    navigate(`/${config.id}`);
  });

  useSubscription("fileValue", (file: any) => {
    setState({ image: file.blob, errorHightlight: false });
  });

  const errors = validateObject(
    {
      name: { regex: /.+/, message: "This field is required." },
    },
    state
  );
  const hasError = (prop: string) => (errors[prop] ? true : false);

  return (
    <div className="brand-item">
      <Heading {...data} />
      <Form title="Brands">
        <TextField
          label="Name"
          onChange={(e) => handleChange("name", e.target.value)}
          value={state.name}
          error={hasError("name")}
        />
        <FileUpload
          label="Brand image"
          className="file"
          value={state.image}
          name={"image"}
          acceptedTypes={["png", "jpg"]}
        />
        <img
          alt="Brand Logo"
          style={{ maxWidth: 250, maxHeight: 250, objectFit: "contain" }}
          src={state.image}
        />
      </Form>
    </div>
  );
}
