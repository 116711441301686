import React from 'react';
import { TextField } from '../../../../components/fields';
import Form from '../../../../components/form/Form';
import './_styles.scss';
import Heading from '../heading/Heading';

interface ViewItemProps {
  data: any;
}

export default function ViewItem({ data }: ViewItemProps) {
  return (
    <div className='category-item'>
      <Heading {...data} />
      <Form title='Category Details'>
        <TextField label='Department' readOnly value={data.department} />
        <br />
        <Heading title='Sub-Deparments' lastname='' />
        {data.subDepartment?.map((sub: any) => (
          <TextField label='Sub-Department' readOnly value={sub} />
        ))}
      </Form>
    </div>
  );
}
