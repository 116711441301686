import React, { useEffect, useState } from "react";
import { TextField } from "../../../../components/fields";
import Form from "../../../../components/form/Form";
import { publish, useStateReducer } from "../../../../hooks";
import {
  asyncify,
  downloadBase64File,
  validateObject,
} from "../../../../utilities";
import Heading from "../heading/Heading";
import { goFetch } from "../../../../utilities/goFetch";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { Button } from "../../../../components";
import AutoComplete, {
  AutoCompleteOption,
} from "../../../../components/fields/AutoComplete";
import { showAlert, hideAlert } from "../../../../components/alert/Alert";
import { AlertTypeEnum } from "../../../../enums";
import dataTemplateService from "../../../../services/dataTemplateService";
import { errorAlert } from "../../../../utilities/fileUtil";

const statuses = [
  { code: "p", name: "Pending" },
  { code: "t", name: "Accepted" },
  { code: "d", name: "Declined" },
];
interface ViewItemProps {
  data?: any;
}

export default function ViewItem({ data }: ViewItemProps) {
  const [state, setState] = useStateReducer({
    ...data,
  });

  const [buyers, setBuyers] = useState<any[]>([]);

  const getAllUserById = async () => {
    var response: any = await goFetch(`user/buyers`).get();
    var jsonResponse = await response.json();

    setBuyers(jsonResponse.buyers);
  };

  useEffect(() => {
    getAllUserById();
    return () => {};
  }, []);

  const handleChange = (prop: string, value: any) => {
    setState({ [prop]: value, errorHightlight: false });
    publish("requestaccess", { ...state, [prop]: value });
  };

  const hasError = (prop: string) => (errors[prop] ? true : false);

  const errors = validateObject(
    {
      orderStatus: { regex: /.+/, message: "This field is required." },
    },
    state
  );
  const hidden = true;
  return (
    <div className="product-catalogue">
      <Heading {...data} />
      <Form title="Request Details">
        <AutoComplete
          options={statuses.map((s) => new AutoCompleteOption(s.name, s.name))}
          onChange={handleChange}
          value={state.status}
          prop={"status"}
          label="Request Status"
        />
        {!hidden && (
          <TextField
            label="Supplier Name"
            value={state.supplierName}
            onChange={(e) => handleChange("supplierName", e.target.value)}
            readOnly
            hidden={true}
          />
        )}
      </Form>
      <Form title="Buyer Details">
        {buyers
          .filter((e: any) => e.id === data.buyerId)
          .map((buyer: any) => (
            <>
              <TextField
                label="Buyer Trading Name"
                value={buyer.buyerTradingName}
                readOnly
              />
              <TextField label="Buyer Email" value={buyer.email} readOnly />

              <Form title="Buyer Delivery Address">
                <TextField
                  label="Adress Line 1"
                  value={buyer.dAddressLine1}
                  readOnly
                />
                <TextField
                  label="Adress Line 2"
                  value={buyer.dAddressLine2}
                  readOnly
                />
                <TextField
                  label="Adress Line 2"
                  value={buyer.dAddressLine1}
                  readOnly
                />
                <TextField label="Area Code" value={buyer.dAreaCode} readOnly />
                <TextField label="Town" value={buyer.dTown} readOnly />
                <TextField label="County" value={buyer.dCounty} readOnly />
                <TextField
                  label="Buyer Proof of Address"
                  value={
                    buyer.proofOfAddress === "" || buyer.proofOfAddress === null
                      ? "No File"
                      : "Proof of Address"
                  }
                  readOnly
                  name="proofOfAddress"
                  endAdornment={
                    <Button
                      icon={faDownload}
                      primary
                      onClick={(_) =>
                        asyncify(
                          () =>
                            buyer.proofOfAddress === "" ||
                            buyer.proofOfAddress === null
                              ? errorAlert()
                              : downloadBase64File(
                                  buyer.proofOfAddress,
                                  "ProofofAddress.pdf"
                                ),
                          0
                        )
                      }
                      className="clear-button"
                    />
                  }
                />
              </Form>
              <Form title="Buyer Company Details">
                <TextField
                  label="Company Registration Number"
                  value={buyer.companyRegNo}
                  readOnly
                />
                <TextField
                  label="Company Registration Document"
                  value={
                    buyer.regDocument === "" || buyer.regDocument === null
                      ? "No File"
                      : "Company Registration Document"
                  }
                  readOnly
                  name="regDocument"
                  endAdornment={
                    <Button
                      icon={faDownload}
                      primary
                      onClick={(_) =>
                        asyncify(
                          () =>
                            buyer.regDocument === "" ||
                            buyer.regDocument === null
                              ? errorAlert()
                              : downloadBase64File(
                                  buyer.regDocument,
                                  "RegDoc.pdf"
                                ),
                          0
                        )
                      }
                      className="clear-button"
                    />
                  }
                />
                <TextField
                  label="Company VAT Registration Number "
                  value={buyer.companyVATRegNo}
                  readOnly
                />
                <TextField
                  label="Company VAT Registration Document"
                  value={
                    buyer.vatRegDocument === "" || buyer.vatRegDocument === null
                      ? "No File"
                      : "Company VAT Registration Document"
                  }
                  readOnly
                  name="vatRegDocument"
                  endAdornment={
                    <Button
                      icon={faDownload}
                      primary
                      onClick={(_) =>
                        asyncify(
                          () =>
                            buyer.vatRegDocument === "" ||
                            buyer.vatRegDocument === null
                              ? errorAlert()
                              : downloadBase64File(
                                  buyer.vatRegDocument,
                                  "VatRegDoc.pdf"
                                ),
                          0
                        )
                      }
                      className="clear-button"
                    />
                  }
                />
              </Form>
            </>
          ))}
      </Form>
    </div>
  );
}
