import { validate } from "uuid";

export default function isEmailValid(email: any) {
  return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);
}

function isNumberValid(tel: any) {
  /^(\+\d{1,3})?\(?\d{1}\)?\(?\d{2}\)?\(?\d{3}\)?\d{2,3}$/i.test(tel);
  return tel.replace(" ", "");
}

function isMobileNumberValid(tel: any) {
  return /^(\+\d{1,3}\-)?\(?\d{2}\-\)?\(?\d{3}\-\)?\d{4}$/i.test(tel);
}

function isUrlValid(website: any) {
  return /(?:https?):\/\/(\w+:?\w*)?(\S+)(:\d+)?(\/|\/([\w#!:.?+=&%!\-/]))?/.test(
    website
  );
}

function isPasswordValid(password: any) {
  return /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/.test(
    password
  );
}

function isSwiftValid(code: any) {
  return /^[A-Z]{6}[A-Z0-9]{2}([A-Z0-9]{3})?$/.test(code);
}

function isIbanValid(iban: any) {
  return /^([A-Z]{2}[ \-]?[0-9]{2})(?=(?:[ \-]?[A-Z0-9]){9,30}$)((?:[ \-]?[A-Z0-9]{3,5}){2,7})([ \-]?[A-Z0-9]{1,3})?$/.test(
    iban
  );
}
function validateIbanChecksum(iban: any) {
  const ibanStripped = iban
    .replace(/[^A-Z0-9]+/gi, "") //keep numbers and letters only
    .toUpperCase(); //calculation expects upper-case
  const m = ibanStripped.match(/^([A-Z]{2})([0-9]{2})([A-Z0-9]{9,30})$/);
  if (!m) return false;

  const numbericed = (m[3] + m[1] + m[2]).replace(/[A-Z]/g, function (ch: any) {
    //replace upper-case characters by numbers 10 to 35
    return ch.charCodeAt(0) - 55;
  });
  //The resulting number would be to long for javascript to handle without loosing precision.
  //So the trick is to chop the string up in smaller parts.
  const mod97 = numbericed
    .match(/\d{1,7}/g)
    .reduce(function (total: any, curr: any) {
      return Number(total + curr) % 97;
    }, "");

  return mod97 === 1;
}
function isSortCodeValid(code: any) {
  return /^(?!(?:0{6}|00-00-00))(?:\d{6}|\d\d-\d\d-\d\d)$/.test(code);
}

function validateAccountNo(number: any) {
  return /^(?!(?:0{15}|01234567|12345678))(\d){15}$/.test(number);
}
export {
  isNumberValid,
  isUrlValid,
  isPasswordValid,
  isSwiftValid,
  isIbanValid,
  isSortCodeValid,
  validateAccountNo,
  isMobileNumberValid,
};
