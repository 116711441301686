import React from "react";
import {
  Autocomplete,
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
  TextField,
} from "@mui/material";
import { classNameBuilder, isObject } from "../../utilities";
interface ListProps {
  label?: string;
  error?: boolean;
  getOptionValue?(option: any): any;
  value?: any;
  className?: string;
  id?: string;
  onChange?: (
    event: React.SyntheticEvent<Element, Event>,
    value: any,
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<any> | undefined
  ) => void;
  disabled?: boolean;
  readOnly?: boolean;
  options: any[];
  getOptionDisabled?: (option: any) => boolean;
  getOptionLabel?: (option: any) => string;
  getOptionValue?: (option: any) => any;
  isOptionEqualToValue?: (option: any, value: any) => boolean;
  multiple?: boolean;
  groupBy?: (option: any) => string;
  allowClear?: boolean;
  onTextChange?: any;
  onFocus?: any;
  disableClose?: boolean;
}
export default function List({
  label,
  value,
  className,
  id,
  onChange,
  error,
  disabled,
  readOnly,
  options,
  getOptionDisabled,
  getOptionLabel,
  getOptionValue,
  isOptionEqualToValue,
  multiple,
  groupBy,
  allowClear,
  onTextChange,
  onFocus,
  disableClose,
}: ListProps) {
  let val;
  if (!value) val = null;
  else if (isObject(value)) val = value;
  else if (getOptionValue) {
    val = options.filter((o) => getOptionValue(o) === value);
    if (!multiple) val = val[0];
  }
  return (
    <Autocomplete
      id={id}
      className={classNameBuilder(
        "h-app-autocomplete-field",
        readOnly ? "readonly" : "",
        className ?? ""
      )}
      onChange={onChange}
      value={val}
      onFocus={onFocus}
      disabled={disabled}
      readOnly={readOnly}
      options={options}
      getOptionDisabled={getOptionDisabled}
      getOptionLabel={getOptionLabel}
      isOptionEqualToValue={(o, v) => v && isOptionEqualToValue?.(o, v)}
      multiple={multiple}
      groupBy={groupBy}
      size="small"
      disableCloseOnSelect={disableClose}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          autoComplete="new-password"
          error={error}
          onChange={onTextChange}
        />
      )}
      disableClearable={!allowClear}
    />
  );
}
