import React from "react";
import { faHandPointer } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect } from "react";
import { PageWrapper } from "../../components";
import { ToolbarContextEnum } from "../../enums";
import { INavigableComponent } from "../../interfaces";
import "./_styles.scss";

export default function HomePage({
  setBreadcrumbs,
  setToolbarConfig,
}: INavigableComponent) {
  useEffect(() => {
    setBreadcrumbs?.([]);
    setToolbarConfig?.({
      context: ToolbarContextEnum.Clear,
    });
  }, []);

  return (
    <PageWrapper
      meta={{ title: "Welcome" }}
      pageId="landing"
      className="h-app-landing"
      secure
    >
      <FontAwesomeIcon icon={faHandPointer} /> Select a module on the left
    </PageWrapper>
  );
}
