import React from "react";
import { useEffect } from "react";
import { PageWrapper } from "../../components";
import { INavigableComponent } from "../../interfaces";
import { global } from "../../_config";
import "./_styles.scss";

export default function SettingsPage({
  setBreadcrumbs,
  setToolbarConfig,
}: INavigableComponent) {
  useEffect(() => {
    setBreadcrumbs?.([{ text: "Settings" }]);
    setToolbarConfig?.([]);
  }, []);

  const Component = global.components.settings;
  const meta = { title: "Settings" };

  return (
    <PageWrapper meta={meta} secure>
      <div className="h-app-settings-page-content">
        <Component />
      </div>
    </PageWrapper>
  );
}
