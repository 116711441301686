import React from "react";
import { faSave } from "@fortawesome/free-solid-svg-icons";
import { ToolButton } from "../../../../components";
import { IToolButtonProps } from "../../../../interfaces";
import { useStateReducer, useSubscription } from "../../../../hooks";
import { useNavigate } from "react-router-dom";

export default function SaveButton({ config, item }: IToolButtonProps) {
  const [state, setState] = useStateReducer({});
  let navigate = useNavigate();

  const { id: moduleId, services } = config;
  const { id: itemId } = item;

  const handleClick = async () => {
    await services.update(state.buyer);

    navigate(-1);
    return true;
  };

  useSubscription("buyer", (buyer: any) => {
    setState({ buyer });
  });
  return (
    <ToolButton
      text="Save"
      icon={faSave}
      path={`/${moduleId}/${itemId}`}
      primary
      raised
      onClick={handleClick}
    />
  );
}
