import React, { FC } from "react";
import "./_styles.scss";

interface InvoiceModalProps {
  order: any;
  orderItems: any;
  product: any;
  supplier: any;
}

const InvoiceModal: FC<InvoiceModalProps> = ({
  order,
  orderItems,
  product,
  supplier,
}) => {
  console.log(product);
  return (
    <div className="invoice-modal">
      <div className="invoice-box" id="invoice-box">
        <table>
          <tr className="top">
            <td colSpan={3}>
              <table>
                <tr>
                  <td>
                    Order Number: {order.orderId}
                    <br />
                    Created: {order.createdAt}
                    <br />
                    Due: {order.createdAt}
                  </td>
                  <td className="title">
                    <img
                      src={supplier.supplierLogo}
                      alt="Company logo"
                      style={{
                        maxWidth: 80,
                        maxHeight: 80,
                        objectFit: "contain",
                        alignSelf: "center",
                      }}
                    />
                  </td>
                </tr>
              </table>
            </td>
          </tr>

          <tr className="information">
            <td colSpan={3}>
              <table>
                <tr>
                  <td>
                    {supplier.addressLine1}
                    <br />
                    {supplier.addressLine2}
                    <br />
                    {supplier.addressLine3}
                  </td>

                  <td>
                    {supplier.supplierName}
                    <br />
                    {supplier.oContactPerson}
                    <br />
                    {supplier.email}
                  </td>
                </tr>
              </table>
            </td>
          </tr>

          <tr className="heading">
            <td>Product</td>
            <td>Quantity Available</td>
            <td>Price</td>
          </tr>

          {product.map((e: any) => (
            <tr className="item">
              <td>{e.title}</td>
              <td>{orderItems.acceptedQuantity}</td>
              <td>
                {e.unitPrice === "" || e.unitPrice === 0
                  ? "€ " + e.casePrice
                  : "€ " + e.unitPrice}
              </td>
            </tr>
          ))}

          <tr className="total">
            <td></td>
            <td></td>

            <td>Total: € {orderItems.total}</td>
          </tr>
        </table>
      </div>
    </div>
  );
};

export default InvoiceModal;
