import { dummyDataService } from "./services";
import { ItemPage, LibraryCard } from "./components";
import { faMoneyBill } from "@fortawesome/free-solid-svg-icons";
import { ModuleTypeEnum } from "../../enums";
import { IModuleConfig } from "../../interfaces";
import {
  AddButton,
  BackButton,
  CancelButton,
  DeleteButton,
  EditButton,
  ExportButton,
  Report1Button,
  Report2Button,
  SaveButton,
} from "./components/toolbar-items";

const config: IModuleConfig = {
  id: "orders",
  name: "Orders",
  icon: faMoneyBill,
  moduleType: ModuleTypeEnum.DualPaneLibrary,
  allowSearch: true,
  components: {
    libraryCard: LibraryCard,
    itemPage: ItemPage,
  },
  services: {
    getByPage: dummyDataService.getByPage,
    getSingle: dummyDataService.getSingle,
    search: dummyDataService.search,
    create: dummyDataService.create,
    update: dummyDataService.update,
    delete: dummyDataService.delete,
  },
  utilities: {
    extractKey: (d: any) => d.id,
    extractItemPath: (d: any) => d.id,
    extractItemBreadcrumb: (d: any) => `${d.orderId}`,
  },
  toolbarItems: {
    library: [],
    view: [
      BackButton,
      "|",
      EditButton,
      // , "|", Report1Button
    ],
    edit: [SaveButton, CancelButton],
    create: [],
  },
};

export default config;
