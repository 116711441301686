import React from "react";
import "./_styles.scss";

interface HeadingProps {
  orderId: string;
}

export default function Heading({ orderId }: HeadingProps) {
  return <h1>{orderId}</h1>;
}
