import React from 'react';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { ToolButton } from '../../../../components';
import { IToolButtonProps } from '../../../../interfaces';
import { publish, useStateReducer, useSubscription } from '../../../../hooks';
import { useNavigate } from 'react-router-dom';
import { hideAlert, showAlert } from '../../../../components/alert/Alert';
import { AlertTypeEnum, StateTopicEnum } from '../../../../enums';

export default function DeleteButton({ config, item }: IToolButtonProps) {
  const [state, setState] = useStateReducer({});
  const { id: moduleId, services } = config;
  const { id: itemId } = item;
  let navigate = useNavigate();

  const handleClick = async () => {
    showAlert({
      content: 'Are you sure you want to delete this Brand?',
      options: {
        actions: [
          { text: 'Confirm', primary: true, onClick: handleDelete },
          { text: 'Cancel', onClick: handleCancel },
        ],
        type: AlertTypeEnum.Question,
      },
    });
  };

  useSubscription('brand', (brand: any) => {
    setState({ brand });
  });

  const handleDelete = async () => {
    await services.delete({ key: itemId });
    hideAlert();
    navigate(`/${moduleId}`);

    publish(StateTopicEnum.LibraryRefresh, true);
  };

  const handleCancel = () => hideAlert();

  return (
    <ToolButton
      text='Delete Brand'
      icon={faTrash}
      path={`/${moduleId}`}
      onClick={handleClick}
    />
  );
}
