import { idText } from "typescript";
import { v4 as uuidv4 } from "uuid";

const dummyLibraryData = [...Array(100).keys()].map((v) => ({
  id: uuidv4(),
  Department : "Drinks",
  SubDepartment : "Multi-Pack",
  isNew : false
}));

export default dummyLibraryData;
