import { v4 as uuidv4 } from "uuid";

const dummyLibraryData = [...Array(50).keys()].map((v) => ({
  id: uuidv4(),
  Status: ``,
  BuyerId: ``,
  SupplierId: ``,
}));

export default dummyLibraryData;
