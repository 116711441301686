import React, { useState } from "react";
import {
  faHashtag,
  faLock,
  faMailForward,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Helmet from "react-helmet";
import { useNavigate } from "react-router-dom";
import { AppLogo, Button } from "../../components";
import { TextField } from "../../components/fields";
import { useAuthentication, useStateReducer } from "../../hooks";
import { classNameBuilder } from "../../utilities";
import { global } from "../../_config";
import "./_styles.scss";
import { goFetch } from "../../utilities/goFetch";
import { showAlert, hideAlert } from "../../components/alert/Alert";
import { AlertTypeEnum } from "../../enums";

export default function ForgotPasswordPage() {
  const navigate = useNavigate();
  const [state, setState] = useStateReducer({
    email: "",
    otp: "",
    sessionId: "",
    password: "",
    confirmPassword: "",
    submitting: false,
    error: false,
    loading: true,
  });
  const handleChange = (prop: string, value: any) =>
    setState({ [prop]: value, errorHightlight: false });

  const handleSend = async () => {
    var response: any = await goFetch("reset/PasswordResetEmailer")
      .withQueryParameters({ email: state.email.toString() })
      .withHeaders({ "Content-Type": "application/json" })
      .post();
    var jsonResponse = await response.json();
    if (jsonResponse.success) {
      setState({ submitting: false, isSend: true });
      showAlert({
        content: `${jsonResponse.message.toString()}`,
        options: {
          type: AlertTypeEnum.Info,
          actions: [
            {
              text: "OK",
              onClick: () => {
                hideAlert();
              },
            },
          ],
        },
      });
    } else {
      showAlert({
        content: "An error occurred. Please try again.",
        options: {
          type: AlertTypeEnum.Error,
          actions: [
            {
              text: "Retry",
              onClick: () => {
                hideAlert();
              },
            },
          ],
        },
      });
    }
    return jsonResponse;
  };

  const handleVerify = async () => {
    var response: any = await goFetch(`reset/PasswordOTPVerification`)
      .withBody({ email: state.email.toString(), otp: state.otp })
      .withHeaders({ "Content-Type": "application/json" })
      .post();
    var jsonResponse = await response.json();

    if (jsonResponse.success) {
      setState({
        submitting: false,
        isSend: false,
        isVerified: true,
        sessionId: jsonResponse.sessionId,
      });
      showAlert({
        content: `OTP Verified Successfully`,
        options: {
          type: AlertTypeEnum.Info,
          actions: [
            {
              text: "OK",
              onClick: () => {
                hideAlert();
              },
            },
          ],
        },
      });
    } else {
      showAlert({
        content: "An error occurred. Please try again.",
        options: {
          type: AlertTypeEnum.Error,
          actions: [
            {
              text: "Retry",
              onClick: () => {
                hideAlert();
              },
            },
          ],
        },
      });
    }
    return jsonResponse;
  };

  const handleChangePassword = async () => {
    var response: any = await goFetch(`reset/PasswordReset`)
      .withBody({
        sessionId: state.sessionId,
        email: state.email.toString(),
        password: state.password,
        confirmPassword: state.confirmPassword,
      })
      .withHeaders({ "Content-Type": "application/json" })
      .post();
    var jsonResponse = await response.json();

    if (jsonResponse.success) {
      setState({ submitting: false, isSend: false, isVeified: false });
      showAlert({
        content: `${jsonResponse.message.toString()}`,
        options: {
          type: AlertTypeEnum.Info,
          actions: [
            {
              text: "OK",
              onClick: () => {
                hideAlert();
                navigate("/login");
              },
            },
          ],
        },
      });
    } else {
      showAlert({
        content: "An error occurred. Please try again.",
        options: {
          type: AlertTypeEnum.Error,
          actions: [
            {
              text: "Retry",
              onClick: () => {
                hideAlert();
              },
            },
          ],
        },
      });
    }
    return jsonResponse;
  };

  const checkForSubmit = (e: any) => {
    if (e.key === "Enter") {
      handleSend();
      setState({ submitting: true });
    }
  };

  return (
    <>
      <Helmet>
        <title>Sign In - {global.applicationName}</title>
      </Helmet>
      <div
        className={classNameBuilder(
          "h-app-login",
          state.loading ? "in" : "",
          state.error ? "error" : "",
          state.errorHightlight ? "error-highlight" : ""
        )}
      >
        <AppLogo />

        <div className="credentials">
          <TextField
            focus={!state.email}
            startAdornment={<FontAwesomeIcon icon={faUser} />}
            placeholder="Email Address"
            value={state.email}
            onChange={(e: any) => handleChange("email", e.target.value)}
            onKeyDown={checkForSubmit}
          />

          {state.isSend && (
            <TextField
              focus={!state.otp}
              startAdornment={<FontAwesomeIcon icon={faHashtag} />}
              placeholder="OTP"
              value={state.otp}
              onChange={(e: any) => handleChange("otp", e.target.value)}
              onKeyDown={checkForSubmit}
            />
          )}
          {state.isVerified && (
            <>
              <TextField
                focus={!state.password}
                startAdornment={<FontAwesomeIcon icon={faLock} />}
                placeholder="Password"
                value={state.password}
                onChange={(e: any) => handleChange("password", e.target.value)}
                onKeyDown={checkForSubmit}
                type="password"
              />
              <TextField
                focus={!state.confirmPassword}
                startAdornment={<FontAwesomeIcon icon={faLock} />}
                placeholder="Confirm Password"
                value={state.confirmPassword}
                onChange={(e: any) =>
                  handleChange("confirmPassword", e.target.value)
                }
                onKeyDown={checkForSubmit}
                type="password"
              />
            </>
          )}
        </div>
        <div className="button-container">
          <Button
            text={
              state.isSend
                ? "Verify OTP"
                : state.isVerified
                ? "Change Password"
                : "Send OTP"
            }
            primary
            raised
            onClick={
              state.isSend
                ? handleVerify
                : state.isVerified
                ? handleChangePassword
                : handleSend
            }
            working={state.submitting}
          />
        </div>
      </div>
    </>
  );
}
