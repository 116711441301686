import React from 'react';
import { faSquarePlus } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { ToolButton } from '../../../../components';
import { hideAlert, showAlert } from '../../../../components/alert/Alert';
import { AlertTypeEnum } from '../../../../enums';
import { IToolButtonProps } from '../../../../interfaces';

export default function AddButton({ config }: IToolButtonProps) {
  const navigate = useNavigate();
  const { id: moduleId, services } = config;

  const handleClick = () => {
    const handleYes = async () => {
      const newId = await services.create();
      hideAlert();
      navigate(`/${moduleId}/${newId}?mode=edit&new=true`, { replace: false });
    };

    showAlert({
      content: 'Are you sure you want to create a new brand?',
      options: {
        actions: [
          { text: 'Yes', onClick: handleYes, primary: true },
          { text: 'No', onClick: hideAlert },
        ],
        type: AlertTypeEnum.Question,
      },
    });
  };

  return (
    <ToolButton
      text='Add'
      icon={faSquarePlus}
      primary
      raised
      onClick={handleClick}
    />
  );
}
