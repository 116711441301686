import React from "react";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Clickable } from "..";

interface SideNavLinkProps {
  to?: string;
  data?: any;
  icon?: IconProp;
  children?: React.ReactNode;
}

export default function SideNavLink({
  to,
  data,
  icon,
  children,
}: SideNavLinkProps) {
  return (
    <Clickable className="h-app-side-nav-link" to={to} data={data} isNavLink>
      {icon && <FontAwesomeIcon icon={icon} />}
      {children}
    </Clickable>
  );
}
