import { IGetSingleArgs, IModuleConfig } from "../interfaces";
import { asyncify } from "../utilities";
import { modules } from "../_config";
interface ModuleService {
  getAllModules(): Promise<IModuleConfig[]>;
  getSingleModule(args: IGetSingleArgs): Promise<IModuleConfig>;
  getSupplierModule(): Promise<IModuleConfig[]>;
}

const service: ModuleService = {
  async getAllModules() {
    return await asyncify(() => modules, 0);
  },

  async getSingleModule({ key }: IGetSingleArgs) {
    return await asyncify(() => modules.filter((m: any) => m.id === key)[0], 0);
  },
  async getSupplierModule() {
    return await asyncify(
      () =>
        modules.filter(
          (m: any) =>
            m === modules[2] ||
            m === modules[3] ||
            m === modules[5] ||
            m === modules[6]
        ),
      0
    );
  },
};

export default service;
