import { Card, CardContent } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  FileUpload,
  InstructionWrapper,
  NumberField,
  TextField,
} from "../../../../components/fields";
import AutoComplete, {
  AutoCompleteOption,
} from "../../../../components/fields/AutoComplete";
import Form from "../../../../components/form/Form";
import { publish, useStateReducer, useSubscription } from "../../../../hooks";
import { validateObject } from "../../../../utilities";
import { goFetch } from "../../../../utilities/goFetch";

const unitOfMeasurements = [
  { code: "each", name: "Each" },
  { code: "gm", name: "Grams" },
  { code: "kg", name: "Kilograms" },
  { code: "ml", name: "Milliliters" },
  { code: "lt", name: "Liters" },
];

const salesConfigs = [{ name: "Unit" }, { name: "Case" }];

const productStat = [{ name: "Active" }, { name: "Inactive" }];

interface ViewItemProps {
  data?: any;
}

export default function ViewItem({ data }: ViewItemProps) {
  const [state, setState] = useStateReducer({
    ...data,
  });

  const [brands, setBrands] = useState<any[]>([]);
  const [categories, setCategories] = useState<any[]>([]);

  const getSubCategories =
    categories.filter((u) => u.department === state.categoryId)[0]
      ?.subDepartment ?? [];

  const handleChange = (prop: string, value: any) => {
    setState({ [prop]: value, errorHightlight: false });
    publish("product", { ...state, [prop]: value, valid: true });
  };

  useSubscription("fileValue", (file: any) => {
    setState({ [file.name]: file.blob, errorHightlight: false });
    publish("product", { ...state, [file.name]: file.blob });
  });

  const errors = validateObject(
    {
      brand: { regex: /.+/, message: "This field is required." },
      title: { regex: /.+/, message: "This field is required." },
      productName: { regex: /.+/, message: "This field is required." },
      category: { regex: /.+/, message: "This field is required." },
      subCategory: { regex: /.+/, message: "This field is required." },
      unitOfMeasurement: { regex: /.+/, message: "This field is required." },
      measurement: { regex: /.+/, message: "This field is required." },
      salesConfig: { regex: /.+/, message: "This field is required." },
      unitsInCase:
        state.salesConfig == "Case"
          ? { regex: /.+/, message: "This field is required." }
          : { regex: /^\s*$/, message: "" },
      productCode: { regex: /.+/, message: "This field is required." },
      unitBarcode: { regex: /.+/, message: "This field is required." },
      caseBarcode:
        state.salesConfig == "Case"
          ? { regex: /.+/, message: "This field is required." }
          : { regex: /^\s*$/, message: "" },
      unitPrice:
        state.salesConfig == "Unit"
          ? { regex: /.+/, message: "This field is required." }
          : { regex: /^\s*$/, message: "" },
      casePrice:
        state.salesConfig == "Case"
          ? { regex: /.+/, message: "This field is required." }
          : { regex: /^\s*$/, message: "" },
      productStatus: { regex: /.+/, message: "This field is required." },
      image: { regex: /.+/, message: "This field is required." },
    },
    state
  );

  const hasError = (prop: string) => (errors[prop] ? true : false);

  useEffect(() => {
    Object.keys(state).forEach((prop) => {
      var hasError = errors[prop] ? true : false;
      if (hasError) {
        publish("product", { ...state, valid: false });
        return;
      }
    });
    return () => {};
  }, [state]);

  useEffect(() => {
    getAllBrands();
    getAllCategories();
    return () => {};
  }, []);

  const getAllBrands = async () => {
    var response: any = await goFetch(`brand/getallbrand`).get();
    var jsonResponse = await response.json();

    setBrands(jsonResponse.brands);
  };

  const getAllCategories = async () => {
    var response: any = await goFetch(`category/getallcategory`).get();
    var jsonResponse = await response.json();

    setCategories(jsonResponse.categories);
  };
  const hidden = true;
  state.supplierId = localStorage.getItem("h-user-id");
  state.unitInCase =
    state.unitInCase == null ||
    state.unitInCase == "" ||
    state.saleConfig == "Unit" ||
    state.saleConfig == null
      ? "1 "
      : state.unitInCase;

  state.unitMeasurement =
    state.unitMeasurement == null ||
    state.unitMeasurement == "" ||
    state.unitOfMeasurement == null
      ? " "
      : state.unitMeasurement;

  state.subCategory =
    state.subCategory == null || state.subCat == "" || state.categoryId == null
      ? " "
      : state.subCategory;
  state.title =
    (state.brand == null ? " " : state.brand) +
    " - " +
    (state.productName == null ? " " : state.productName) +
    " ( " +
    state.unitInCase +
    " x " +
    state.unitMeasurement +
    (state.unitOfMeasurement == "each"
      ? "units"
      : state.unitOfMeasurement == null
      ? ""
      : state.unitOfMeasurement) +
    ")";

  const umLabel =
    state.unitOfMeasurement != "" ? state.unitOfMeasurement + " per unit" : "";

  const onBrandCreate = async (value: string) => {
    const res: any = await goFetch(`brand/create`)
      .withHeaders({ "Content-Type": "application/json" })
      .withBody({ name: value })
      .post();
  };

  return (
    <div className="product-catalogue">
      <div className="sticky">
        {/* <Heading lin {} /> */}
        <h1>{state.title}</h1>
      </div>
      <Form title="Product Details">
        <InstructionWrapper text="Choose the Product Brand.">
          <AutoComplete
            options={brands.map(
              (brand: any) => new AutoCompleteOption(brand.name, brand.name)
            )}
            label="Brand"
            prop="brand"
            onChange={handleChange}
            value={state.brand}
            error={hasError("brand")}
            canCreate
            onCreate={(p, v) => onBrandCreate(v)}
          />
        </InstructionWrapper>
        <InstructionWrapper
          text="Enter the Product Name/Variant here as it appears on the Product Label."
          row
        >
          <TextField
            label="Product Name/Variant"
            value={`${state.productName}`}
            onChange={(e) => handleChange("productName", e.target.value)}
            error={hasError("productName")}
          />
        </InstructionWrapper>
        {!hidden && (
          <>
            <InstructionWrapper
              text="Enter the Product Name/Variant here As it appears on Product Label."
              row
            >
              <TextField
                aria-hidden={true}
                label="Product Name/Variant"
                value={`${state.title}`}
                hidden={true}
                onChange={(e) => handleChange("title", e.target.value)}
              />
            </InstructionWrapper>
          </>
        )}
        <InstructionWrapper text="Choose the Product's Category.">
          <AutoComplete
            options={categories.map(
              (category: any) =>
                new AutoCompleteOption(category.department, category.department)
            )}
            label="Category"
            prop="categoryId"
            onChange={handleChange}
            value={state.categoryId}
            error={hasError("category")}
          />
        </InstructionWrapper>
        {state.categoryId !== "" && state.categoryId !== null && (
          <>
            <InstructionWrapper text="Choose the Product's Sub-Category.">
              <AutoComplete
                options={getSubCategories.map(
                  (subCategory: any) =>
                    new AutoCompleteOption(subCategory, subCategory)
                )}
                label="Sub-Category"
                prop="subCategory"
                onChange={handleChange}
                value={state.subCategory}
                error={hasError("subCategory")}
              />
            </InstructionWrapper>
          </>
        )}
        <InstructionWrapper text="Choose the Unit of Measure.">
          <AutoComplete
            options={unitOfMeasurements.map(
              (option) => new AutoCompleteOption(option.name, option.code)
            )}
            label="Unit of Measure"
            prop="unitOfMeasurement"
            onChange={handleChange}
            value={state.unitOfMeasurement}
            error={hasError("unitOfMeasurement")}
          />
        </InstructionWrapper>
        {state.unitOfMeasurement !== "each" &&
          (state.unitOfMeasurement?.length > 0 ?? false) && (
            <>
              <InstructionWrapper text="Type in the Unit of Measure.">
                <TextField
                  label={umLabel}
                  onChange={(e) =>
                    handleChange("unitMeasurement", e.target.value)
                  }
                  value={state.unitMeasurement}
                  min={1}
                  error={hasError("unitMeasurement")}
                  type="number"
                />
              </InstructionWrapper>
            </>
          )}

        <InstructionWrapper text="Choose the Sale Configuration to Store.">
          <AutoComplete
            options={salesConfigs.map(
              (option) => new AutoCompleteOption(option.name, option.name)
            )}
            label="Sales Configuration to Store"
            prop="saleConfig"
            onChange={handleChange}
            value={state.saleConfig}
            error={hasError("saleConfig")}
          />
        </InstructionWrapper>
        {state.saleConfig === "Case" && state.saleConfig !== "Unit" && (
          <>
            <InstructionWrapper text="Choose the number of Units in a Case.">
              <TextField
                label="Units in a Case"
                onChange={(e) => handleChange("unitInCase", e.target.value)}
                value={state.unitInCase}
                error={hasError("unitInCase")}
                min={2}
                type="number"
              />
            </InstructionWrapper>
            <InstructionWrapper text="Enter the Case Barcode here." row>
              <TextField
                label="Case Barcode"
                onChange={(e) => handleChange("caseBarcode", e.target.value)}
                value={state.caseBarcode}
                error={hasError("caseBarcode")}
                type="number"
              />
            </InstructionWrapper>
          </>
        )}
        <InstructionWrapper text="Enter the Supplier Product Code here." row>
          <TextField
            label="Supplier Product Code"
            onChange={(e) => handleChange("productCode", e.target.value)}
            value={state.productCode}
            error={hasError("productCode")}
          />
        </InstructionWrapper>
        <InstructionWrapper text="Enter the Unit Barcode here." row>
          <TextField
            label="Unit Barcode"
            onChange={(e) => handleChange("unitBarcode", e.target.value)}
            value={state.unitBarcode}
            error={hasError("unitBarcode")}
            type="number"
          />
        </InstructionWrapper>
        {state.saleConfig === "Unit" && (
          <>
            <InstructionWrapper
              text="Enter the Unit Cost Price Excl. VAT here."
              row
            >
              <NumberField
                label="Unit Cost Price Excl. VAT"
                onChange={(e) => handleChange("unitPrice", e.target.value)}
                value={state.unitPrice}
                error={hasError("unitPrice")}
                startAdornment="€ "
                decimalScale={2}
              />
            </InstructionWrapper>
          </>
        )}
        {state.saleConfig === "Case" && (
          <>
            <InstructionWrapper
              text="Enter the Case Cost Price Excl. VAT here."
              row
            >
              <NumberField
                label="Case Cost Price Excl. VAT"
                onChange={(e) => handleChange("casePrice", e.target.value)}
                value={state.casePrice}
                error={hasError("casePrice")}
                startAdornment="€ "
              />
            </InstructionWrapper>
          </>
        )}
        <InstructionWrapper text="Upload Product Image.">
          <FileUpload
            label="Product image"
            className="file"
            // onChange={(e) => handleChange("name", e.target.value)}
            value={state.image}
            acceptedTypes={["png", "jpg"]}
            name="image"
            error={hasError("image")}
          />
          <Card sx={{ width: 300 }}>
            <CardContent>
              <img
                style={{
                  maxWidth: 250,
                  maxHeight: 250,
                  objectFit: "contain",
                  alignSelf: "center",
                }}
                src={state.image}
              />
            </CardContent>
          </Card>
        </InstructionWrapper>
        <InstructionWrapper text="Select Product Status. Default Active.">
          <AutoComplete
            options={productStat.map(
              (option) => new AutoCompleteOption(option.name, option.name)
            )}
            label="Product Status"
            prop="productStatus"
            onChange={handleChange}
            value={state.productStatus}
          />
        </InstructionWrapper>
      </Form>
    </div>
  );
}
