import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCamera } from "@fortawesome/free-solid-svg-icons";
import "./_styles.scss";

export default function AppLogo() {
  return (
    <div className="app-logo">
      <FontAwesomeIcon icon={faCamera} />
      LOGO
    </div>
  );
}
