import { faDownload } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import { Button } from "../../../../components";
import { showAlert, hideAlert } from "../../../../components/alert/Alert";
import { InstructionWrapper, TextField } from "../../../../components/fields";
import AutoComplete, {
  AutoCompleteOption,
} from "../../../../components/fields/AutoComplete";
import Form from "../../../../components/form/Form";
import { AlertTypeEnum } from "../../../../enums";
import { publish, useStateReducer } from "../../../../hooks";
import {
  asyncify,
  downloadBase64File,
  validateObject,
} from "../../../../utilities";
import Heading from "../heading/Heading";

interface ViewItemProps {
  data?: any;
}

export default function ViewItem({ data }: ViewItemProps) {
  const [state, setState] = useStateReducer({
    ...data,
  });
  const userStat = [
    { name: "Pending" },
    { name: "Accepted" },
    { name: "Declined" },
  ];
  const handleChange = (prop: string, value: any) => {
    setState({ [prop]: value });
    publish("buyer", { ...state, [prop]: value });
  };

  const hasError = (prop: string) => (errors[prop] ? true : false);

  const errors = validateObject(
    {
      // name: { regex: /.+/, message: "This field is required." },
      // email: { regex: /.+/, message: "This field is required." },
      // phoneNumber: { regex: /.+/, message: "Please select a gender." },
      // contactPerson: { regex: /.+/, message: "Please select a country." },
    },
    state
  );
  const errorAlert = () => {
    showAlert({
      content: "No File to download.",
      options: {
        actions: [
          {
            text: "OK",
            onClick: hideAlert,
            primary: true,
          },
        ],
        type: AlertTypeEnum.Error,
      },
    });
  };
  return (
    <div className="buyer-item">
      <div className="item-header">
        <Heading lin {...data} />
      </div>

      <Form title="Personal Details">
        <InstructionWrapper text="Enter the buyer's first Name here." row>
          <TextField label="Buyer Name" value={state.name} readOnly />
        </InstructionWrapper>
        <InstructionWrapper text="Enter the buyer's Trading Name here." row>
          <TextField
            label="Buyer Trading Name"
            value={state.surname}
            readOnly
          />
        </InstructionWrapper>
        <InstructionWrapper text="Enter the buyer's email here." row>
          <TextField label="email" value={state.email} readOnly />
        </InstructionWrapper>
      </Form>
      <Form title="Company Address Details">
        <InstructionWrapper text="Buyer's Company Address Details.">
          <TextField
            label="Address Line 1"
            value={state.addressLine1}
            readOnly
          />
          <TextField
            label="Address Line 2"
            value={state.addressLine2}
            readOnly
          />
          <TextField
            label="Address Line 3"
            value={state.addressLine3}
            readOnly
          />
          <TextField label="Area Code" value={state.areaCode} readOnly />
          <TextField label="Town" value={state.town} readOnly />
          <TextField label="County" value={state.county} readOnly />
          <TextField
            label="Proof of Address"
            value={state.proofOfAddress === "" ? "No File" : "Proof of Address"}
            readOnly
            name="proofOfAddress"
            endAdornment={
              <Button
                icon={faDownload}
                primary
                onClick={(_) =>
                  asyncify(
                    () =>
                      state.proofOfAddress === "" ||
                      state.proofOfAddress === null
                        ? errorAlert()
                        : downloadBase64File(
                            state.proofOfAddress,
                            "ProofOfAddress.pdf"
                          ),
                    0
                  )
                }
                className="clear-button"
              />
            }
          />
        </InstructionWrapper>
      </Form>
      <Form title="Delivery Address Details">
        <InstructionWrapper text="Buyer's Delivery Address Details.">
          <TextField
            label="Address Line 1"
            value={state.dAddressLine1}
            readOnly
          />
          <TextField
            label="Address Line 2"
            value={state.dAddressLine2}
            readOnly
          />
          <TextField
            label="Address Line 3"
            value={state.dAddressLine3}
            readOnly
          />
          <TextField label="Area Code" value={state.dAreaCode} readOnly />
          <TextField label="Town" value={state.dTown} readOnly />
          <TextField label="County" value={state.dCounty} readOnly />
          <TextField
            label="Delivery Proof of Address"
            value={
              state.dProofOfAddress === ""
                ? "No File"
                : "Delivery Proof of Address"
            }
            readOnly
            name="dProofOfAddress"
            endAdornment={
              <Button
                icon={faDownload}
                primary
                onClick={(_) =>
                  asyncify(
                    () =>
                      state.dProofOfAddress === "" ||
                      state.dProofOfAddress === null
                        ? errorAlert()
                        : downloadBase64File(
                            state.dProofOfAddress,
                            "DeliveryProofOfAddress.pdf"
                          ),
                    0
                  )
                }
                className="clear-button"
              />
            }
          />
        </InstructionWrapper>
      </Form>
      <Form title="Company Registration Details">
        <TextField
          label="Company Registration Number"
          value={state.companyRegNo}
          readOnly
        />
        <TextField
          label="Company Registration Document"
          value={
            data.regDocument === ""
              ? "No File"
              : "Company Registration Document"
          }
          readOnly
          name="regDocument"
          endAdornment={
            <Button
              icon={faDownload}
              primary
              onClick={(_) =>
                asyncify(
                  () =>
                    data.regDocument === "" || data.regDocument === null
                      ? errorAlert()
                      : downloadBase64File(data.regDocument, "RegDocument.pdf"),
                  0
                )
              }
              className="clear-button"
            />
          }
        />
        <TextField
          label="Company VAT Registration Number "
          value={state.companyVATRegNo}
          readOnly
        />
        <TextField
          label="Company VAT Registration Document"
          value={
            data.vatRegDocument === ""
              ? "No File"
              : "Company VAT Registration Document"
          }
          readOnly
          name="vatRegDocument"
          endAdornment={
            <Button
              icon={faDownload}
              primary
              onClick={(_) =>
                asyncify(
                  () =>
                    data.vatRegDocument === "" || data.vatRegDocument === null
                      ? errorAlert()
                      : downloadBase64File(
                          data.vatRegDocument,
                          "VatRegDocument.pdf"
                        ),
                  0
                )
              }
              className="clear-button"
            />
          }
        />
      </Form>
      <Form>
        <AutoComplete
          options={userStat.map(
            (option) => new AutoCompleteOption(option.name, option.name)
          )}
          label="User Status"
          prop="userStatus"
          onChange={handleChange}
          value={state.userStatus}
          error={hasError("userStatus")}
        />
      </Form>
    </div>
  );
}
