import React, { useEffect } from 'react';
import { Pagination } from '@mui/material';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useStateReducer, useSubscription } from '../../hooks';
import { getQueryParameter } from '../../utilities';
import Scrollable from '../scrollable/Scrollable';
import LibraryCard from './LibraryCard';
import './_styles.scss';
import { StateTopicEnum } from '../../enums';
import { IModuleConfig } from '../../interfaces';

interface LibraryProps {
  config: IModuleConfig;
}

export interface SearchAndPage {
  search?: string;
  page?: number;
  limit?: number;
}

export default function Library({ config }: LibraryProps) {
  const { id: moduleId, services, components, utilities } = config;
  const { libraryCard } = components;
  const { getByPage } = services;
  const [searchParams, setSearchParams] = useSearchParams();
  const { extractKey, extractItemPath } = utilities;
  const location = useLocation();
  const navigate = useNavigate();
  const pageQuery = getQueryParameter<number>(
    location.search,
    'page',
    parseInt
  );
  const [state, setState] = useStateReducer({
    page: isNaN(parseInt(pageQuery.toString())) ? 1 : pageQuery,
    search: getQueryParameter(location.search, 'search'),
    data: null,
  });
  const { pageCount, data } = state.data ?? {};
  const { search, page: pageNo, limit: limit } = state;

  const loadData = async () => {
    setState({ data: await getByPage({ search, pageNo, limit }) });
  };

  useEffect(() => {
    loadData();
  }, [pageNo, search, limit, getByPage, searchParams]);

  useSubscription(StateTopicEnum.LibrarySearchAndPage, (v: SearchAndPage) => {
    setState({
      page: v.page,
      search: v.search,
    });
  });

  useSubscription(StateTopicEnum.LibraryRefresh, () => {
    loadData();
  });

  const handlePage = (_: any, p: number) => {
    let query = search ? `?search=${search}` : '';
    query += `${query ? '&' : '?'}page=${p}`;

    setState({ page: p });
    navigate(`/${moduleId}${query}`, { replace: false });
  };

  return (
    <div className='h-app-library'>
      <Scrollable className='items'>
        <div className='h-app-library-items'>
          {!data ? (
            <span className='loading'>Loading...</span>
          ) : (
            data.map((d: any) => {
              const Card = libraryCard;
              const key = extractKey(d);

              return (
                <LibraryCard
                  key={`library-item-${key}`}
                  to={extractItemPath(d)}
                >
                  <Card data={d} />
                </LibraryCard>
              );
            })
          )}
        </div>
      </Scrollable>
      {pageCount ? (
        <Pagination
          className='pager'
          shape='rounded'
          size='small'
          page={isNaN(pageNo) ? 1 : pageNo}
          count={isNaN(pageCount) ? 1 : pageCount}
          color='primary'
          siblingCount={0}
          boundaryCount={1}
          showFirstButton
          showLastButton
          onChange={handlePage}
        />
      ) : null}
    </div>
  );
}
