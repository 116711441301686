import { idText } from "typescript";
import { v4 as uuidv4 } from "uuid";

const dummyLibraryData = [...Array(100).keys()].map((v) => ({
  id: uuidv4(),
  Title : "New Coke",
  Brand : "Coca",
  UnitSize : "6",
  Description : "New Coke test",
  CaseSize : "4",
  UnitPrice : "50",
  isNew : false
}));

export default dummyLibraryData;
