import { FormControl, InputLabel, OutlinedInput } from "@mui/material";
import React, { ChangeEvent, useRef, useState } from "react";
import { asyncify, classNameBuilder, getFileType } from "../../utilities";
import { v4 as uuidv4 } from "uuid";
import Button from "../button/Button";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { publish } from "../../hooks/useSubscription";

interface FileValue {
  display: string;
  path: string;
}

interface FileUploadProps {
  id?: string;
  label: string;
  value?: FileValue;
  onChange?: (value: FileValue) => void;
  className?: string;
  disabled?: boolean;
  readOnly?: boolean;
  error?: boolean;
  acceptedTypes?: string[];
  name?: string;
}

export default function FileUpload({
  id,
  label,
  className,
  disabled,
  value,
  acceptedTypes,
  name,
  error,
}: FileUploadProps) {
  const [htmlId] = useState(id ?? uuidv4());
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [fileValue, setFileValue] = useState(value?.display ?? "");
  const fileName = fileValue ? fileValue.split(/(\\|\/)/g).pop() : "";
  const { fileClass, fileIcon } = getFileType(fileValue);

  const convertBase64 = (event: any) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      const file = event.target.files[0];
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };
  const handleUploadClick = async (
    _: React.MouseEventHandler<HTMLButtonElement>
  ) => {
    await asyncify(() => fileInputRef.current?.click(), 0);
  };

  const handleFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
    setFileValue(e.target.value);
    let blob = await convertBase64(e);
    publish("fileValue", { blob, name: e.target.name });
  };

  const handleClear = async (_: React.MouseEventHandler<HTMLButtonElement>) => {
    await asyncify(() => setFileValue(""), 0);
    await asyncify(() => !fileValue, 0);
  };

  return (
    <FormControl
      className={classNameBuilder(
        "h-app-input-field",
        "h-app-file-upload",
        className ?? ""
      )}
    >
      <InputLabel htmlFor={htmlId}>{label}</InputLabel>
      <OutlinedInput
        id={htmlId}
        label={label}
        disabled={disabled}
        readOnly
        value={fileName || "No file selected"}
        size="small"
        error={error}
        startAdornment={
          fileValue ? (
            <FontAwesomeIcon
              icon={fileIcon}
              className={classNameBuilder("file-icon", fileClass)}
            />
          ) : null
        }
        endAdornment={
          !fileValue ? (
            <Button text="Upload" primary onClick={handleUploadClick} />
          ) : (
            <Button
              icon={faTimes}
              primary
              onClick={handleClear}
              className="clear-button"
            />
          )
        }
      />
      <input
        ref={fileInputRef}
        type="file"
        hidden
        onChange={handleFileChange}
        value={""}
        name={name}
        accept={acceptedTypes?.map((t) => `.${t}`).join(",")}
      />
    </FormControl>
  );
}
