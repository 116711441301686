import React from "react";
import { Scrollable } from "../../components";
import { classNameBuilder } from "../../utilities";
import "./_styles.scss";

interface PageContentProps {
  index?: boolean;
  loading?: boolean;
  itemId: string;
  children?: React.ReactNode;
}

export default function PageContent({
  index,
  loading,
  itemId,
  children,
}: PageContentProps) {
  const classes = classNameBuilder(
    "h-app-item-page-content",
    index ? "index" : "",
    loading ? "loading" : ""
  );

  return (
    <div className={classes} key={`item-page-${itemId}`}>
      {!index && !loading ? <Scrollable>{children}</Scrollable> : children}
    </div>
  );
}
