import React, { Fragment } from "react";
import { Helmet } from "react-helmet";
import "./_styles.scss";
import { global } from "../../_config";
import { Secure } from "../../AuthProvider";

interface PageWrapperProps {
  pageId?: string;
  meta?: Meta;
  className?: string;
  secure?: boolean;
  children?: React.ReactNode;
}

interface Meta {
  title?: string;
  description?: string;
  keywords?: string;
}

export default function PageWrapper({
  pageId,
  meta,
  className,
  secure,
  children,
}: PageWrapperProps) {
  const { title, description, keywords } = meta ?? {};
  let classes = ["h-page-body"];

  if (className?.length) classes = classes.concat(className.split(" "));

  const Wrapper = secure ? Secure : Fragment;

  return (
    <Wrapper>
      <Helmet>
        {title && (
          <title>
            {title} - {global.applicationName}
          </title>
        )}
        {description && <meta name="description" content={description} />}
        {keywords && <meta name="keywords" content={keywords} />}
      </Helmet>
      <div className={classes.join(" ")} key={pageId}>
        {children}
      </div>
    </Wrapper>
  );
}
