import { dummyDataService } from "./services";
import { ItemPage, LibraryCard } from "./components";
import {
  AddButton,
  BackButton,
  CancelButton,
  DeleteButton,
  EditButton,
  ExportButton,
  Report1Button,
  Report2Button,
  SaveButton,
} from "./components/toolbar-items";
import { faPerson } from "@fortawesome/free-solid-svg-icons";
import { ModuleTypeEnum } from "../../enums";
import { IModuleConfig } from "../../interfaces";

const config: IModuleConfig = {
  id: "buyers",
  name: "Buyer Users",
  icon: faPerson,
  moduleType: ModuleTypeEnum.DualPaneLibrary,
  allowSearch: true,
  services: {
    getByPage: dummyDataService.getByPage,
    getSingle: dummyDataService.getSingle,
    search: dummyDataService.search,
    create: dummyDataService.create,
    update: dummyDataService.update,
    delete: dummyDataService.delete,
  },
  components: {
    libraryCard: LibraryCard,
    itemPage: ItemPage,
  },
  utilities: {
    extractKey: (d: any) => d.id,
    extractItemPath: (d: any) => d.id,
    extractItemBreadcrumb: (d: any) => `${d.name}`,
  },
  // Add toolbar items in order they should render in the toolbar
  toolbarItems: {
    library: [
      // AddButton, "|", ExportButton
    ],
    view: [
      BackButton,
      "|",
      EditButton,
      // DeleteButton,
      // "|",
      // Report1Button,
      // Report2Button,
    ],
    edit: [SaveButton, CancelButton],
    create: [],
  },
};

export default config;
