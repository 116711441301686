import React from "react";
import "./_styles.scss";

export default function SettingsPage() {
  return (
    <div className="settings-page">
      Customizable settings page renders here!
      <p>Located in "/src/_global/components/settings-page"</p>
    </div>
  );
}
