import React from "react";
import "./_styles.scss";

interface SideNavProps {
  children?: React.ReactNode;
}

export default function SideNav({ children }: SideNavProps) {
  return <nav className="h-app-side-nav">{children}</nav>;
}
